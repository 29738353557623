import React, {useEffect, useState} from 'react';
import 'primereact/resources/primereact.min.css';
import ProfileMenu from "../Menu/ProfileMenu";
import InlineProfileMenu from "../Menu/InlineProfileMenu";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {setActiveClick} from "../../redux/actions/actions";
import {DEV, STAGE} from "../../redux/config";


const CompaniesHeader = (props: any) => {
    const dispatch = useAppDispatch();
    const [profileMenuActive, setProfileMenuActive] = useState(false);
    const loader = useAppSelector(state => state.MonitoringListReducer.loading);
    const queueLoader = useAppSelector(state => state.QueueListReducer.loading);
    // @ts-ignore
    const usersLoader = useAppSelector(state => state.UsersListReducer.loading);
    // @ts-ignore
    const companiesLoader = useAppSelector(state => state.CompaniesListReducer.loading);
    const skillGroupLoader = useAppSelector(state => state.QualificationGroupListReducer.loading);
    const rolesLoader = useAppSelector(state => state.RolesReducer.loading);
    const branchLoading = useAppSelector(state => state.BranchesListReducer.loading);
    const statusesLoader = useAppSelector(state => state.StatusesListReducer.loading);
    const trunksLoader = useAppSelector(state => state.TrunksListReducer.loading);
    const audioListLoader = useAppSelector(state => state.AudioListReducer.loading);
    const statisticListLoader = useAppSelector(state => state.StatisticsListReducer.loading);
    const statisticCallsListLoader = useAppSelector(state => state.StatisticsCallsListReducer.loading);
    const statisticStatusListLoader = useAppSelector(state => state.StatisticsStatusListReducer.loading);
    const recallNumberChanged = useAppSelector(state => state.WebRTCReducer.recallNumberChanged);
    const profileMenuActiveChange = (active: any) => {
        // props.profileMenuPinClose(false);
        props.profileMenuPinChange(active)
        setProfileMenuActive(active);
    };

    useEffect(() => {
        props.profileMenuPinChange(true)
        setProfileMenuActive(true);
    }, [recallNumberChanged]);

    useEffect(() => {
        props.profileMenuPinChange(false)
        setProfileMenuActive(false);
    }, [])

    const loaders = {
        'Монiторинг Подiй': {isLoading: loader, reloadIcon: true},
        'Вхідні черги': {isLoading: queueLoader, reloadIcon: true},
        'Користувачі': {isLoading: usersLoader, reloadIcon: true},
        'Вихідні кампанії': {isLoading: companiesLoader, reloadIcon: true},
        'Групи кваліфікацій': {isLoading: skillGroupLoader, reloadIcon: true},
        'Ролі користувачів': {isLoading: rolesLoader, reloadIcon: true},
        'Branch': {isLoading: branchLoading, reloadIcon: true},
        'Статуси': {isLoading: statusesLoader, reloadIcon: true},
        'Транки': {isLoading: trunksLoader, reloadIcon: true},
        'Бібліотека аудіо': {isLoading: audioListLoader, reloadIcon: true},
        'Дані дзвінків': {isLoading: statisticListLoader, reloadIcon: true},
        'Дзвінки операторів': {isLoading: statisticCallsListLoader, reloadIcon: true},
        'Статуси операторів': {isLoading: statisticStatusListLoader, reloadIcon: true},
    };

    const foundItem = props?.model?.find((item: { label: any; }) => item.label === props.title);
    const statisticSection = props?.model?.find((item: { name: string; }) => item.name === "Statistic");
    const settingSection = props?.model?.find((item: { name: string; }) => item.name === "Settings");
    const foundItemSettings = settingSection?.items?.find((item: { label: any; }) => item.label === props.title);
    const foundItemStatistics = statisticSection?.items?.find((item: { label: any; }) => item.label === props.title);


    return (
        <div className={`col-12 header ${DEV ? 'header-dev' : STAGE ? 'header-stage' : ''}`}>
            <div className="card topbar mb-0">
                <div className="topbar-left-block align-items-center">
                    <div className="topbar-left-block-bg"></div>
                    <div style={{minWidth: '30px'}} className="flex justify-content-end">
                        {Object.entries(loaders).map(([title, {isLoading, reloadIcon}], index) =>
                                props?.title === title && (
                                    isLoading ? (
                                        <div style={{marginTop: '2px'}} key={index}>
                                            <i className="pi pi-spin pi-spinner" style={{fontSize: '1.2rem'}}></i>
                                        </div>
                                    ) : reloadIcon && (
                                        <div style={{marginTop: '2px'}} key={index} className="cursor-pointer">
                                            <i className="pi pi-refresh" onClick={() => {
                                                dispatch(setActiveClick({
                                                    item: foundItem?.to || foundItemSettings?.to || foundItemStatistics?.to ||  '/',
                                                    from: window.location.hash,
                                                    click: true
                                                }))
                                            }} style={{fontSize: '1.2rem'}}></i>
                                        </div>
                                    )
                                )
                        )}
                    </div>
                    <h5 className="topbar-title">{props.title === 'Статуси' ? 'Статуси користувачів' : props.title}</h5>
                </div>
                <div className="layout-rightmenu-button-desktop">
                    <ProfileMenu statusHubConnectionState={props.statusHubConnectionState} profileMenuPin={props.profileMenuPin} profileMenuActive={profileMenuActive} onRightMenuPinChange={props.profileMenuPinChange} profileMenuActiveChange={profileMenuActiveChange}/>
                    <InlineProfileMenu statusHubConnectionState={props.statusHubConnectionState} isDesktop={props.isDesktop} profileMenuActiveChange={profileMenuActiveChange}/>
                </div>
            </div>
        </div>
    );
};

export default CompaniesHeader;
