import {DataTable} from "primereact/datatable";
import React, {useRef} from "react";
import {Column} from "primereact/column";
import {useAppSelector} from "../../../redux/hooks";

const QualificationGroupDialogCampaign = () => {
    const dataTableRef = useRef<DataTable | any>(null);
    // @ts-ignore
    const qualificationData = useAppSelector(state => state.QualificationGroupListReducer.qualificationData);

    const updatedUserCampaignInfo = qualificationData?.campaignInfo?.map((item: { id: any; name: any; skillGroupLevel: any; }) => ({
        campaignId: item.id,
        campaignName: item.name,
        skillGroupLevel: item.skillGroupLevel
    }));

    const updatedUserQueuesInfo = qualificationData?.queuesInfo?.map((item: { id: any; name: any; skillGroupLevel: any; }) => ({
        queueId: item.id,
        queueName: item.name,
        skillGroupLevel: item.skillGroupLevel
    }));

    const combinedArray = updatedUserCampaignInfo?.concat(updatedUserQueuesInfo);

    const queueIdWithName = (rowData: any) => {
        if (rowData?.queueId) {
            return (
                <p>{rowData?.queueId} {rowData?.queueName}</p>
            );
        }

        return null;
    }

    const campaignIdWithName = (rowData: any) => {
        if (rowData?.campaignId) {
            return (
                <p>{rowData?.campaignId} {rowData?.campaignName}</p>
            );
        }

        return null;
    }

    return (
        <DataTable value={combinedArray}
                   ref={dataTableRef}
                   className='qualGroup'
                   emptyMessage="Нічого не знайдено"
        >
            <Column
                style={{cursor: 'pointer', width: '20%'}}
                field="skillGroupLevel"
                className='border-bottom-1'
                sortable={false}
                header="Квал рiвень"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
            />
            <Column
                style={{cursor: 'pointer', width: '40%'}}
                field="qualGroup"
                className='border-bottom-1'
                sortable={false}
                header="ID/ Назва Черги"
                body={queueIdWithName}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
            />
            <Column
                style={{cursor: 'pointer', width: '40%'}}
                field="qualGroup"
                className='border-bottom-1'
                sortable={false}
                header="ID/ Назва Кампанii"
                body={campaignIdWithName}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
            />
        </DataTable>
    );
}

export default QualificationGroupDialogCampaign;
