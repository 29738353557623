import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Button} from "primereact/button";
import {MultiSelect} from "primereact/multiselect";
import {OverlayPanel} from "primereact/overlaypanel";
import {Tag} from "primereact/tag";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import {getStatisticExcel, updateUserStatisticFields} from "../../../../redux/api/apiStatisticsList";
import {setClearFilters} from "../../../../redux/actions/actions";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";

const StatisticsListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const exportLoading = useAppSelector(state => state.StatisticsListReducer.exportLoading);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const statisticPermissions = checkPermissions(rolesVision, 'Statistic');
    const userStatisticSortFields: any = useAppSelector(state => state.RolesReducer?.userStatisticSortFields);
    const [selectedFields, setSelectedFields] = useState<any>(userStatisticSortFields?.fields);
    const op = useRef(null);
    const statisticsList = useAppSelector(state => state.StatisticsListReducer?.statisticsList);
    const [isListLength, setIsListLength] = useState(false);

    useEffect(() => {
        setIsListLength(!!statisticsList?.length);
    }, [statisticsList]);

    const {
        update: hasUpdatePermission,
    } = statisticPermissions;

    let filteredObject = {
        "sort": props.sortData?.sort,
        "filter": props.sortData?.filter
    };

    useEffect(() => {
        setSelectedFields(userStatisticSortFields?.fields);
    }, []);

    useEffect(() => {
        setSelectedFields(userStatisticSortFields?.fields);
    }, [userStatisticSortFields]);

    const onColumnToggle = (e: { value: { name: any; }[]; }) => {
        const updatedFields = selectedFields.map((field: { name: any; inSystem: any; }) => {
            if (!field.inSystem) {
                return {
                    ...field,
                    isActive: e.value.some((selected: { name: any; }) => selected.name === field.name)
                };
            }
            return field;
        });
        setSelectedFields(updatedFields);
    };

    const saveUserFields = () => {
        dispatch(updateUserStatisticFields(selectedFields, jwtToken?.jwtToken));
    };

    const itemTemplate = (option: any) => {
        let itemName: string;
        switch (option.name) {
            case "nameTo":
                itemName = 'Клiент';
                break;
            case "ownerCall":
                itemName = 'Власник дзвінка';
                break;
            case "fullName":
                itemName = 'Оператор';
                break;
            case "direction":
                itemName = 'Тип Активності';
                break;
            case "campaignFullName":
                itemName = 'ID / Назва Кампанii';
                break;
            case "queueFullName":
                itemName = 'ID / Назва Черги';
                break;
            case "status":
                itemName = 'Статус';
                break;
            case "typeChannel":
                itemName = 'Активність';
                break;
            case "callResetName":
                itemName = 'Завершив';
                break;
            case "amdHangup":
                itemName = 'Автовідповідач';
                break;
            case "start":
                itemName = 'Початок';
                break;
            case "duration":
                itemName = 'Тривалість розмови';
                break;
            case "fullCallDuration":
                itemName = 'Тривалість дзвінка';
                break;
            case "reactionClient":
                itemName = 'Реакція Клієнта';
                break;
            case "reactionOperator":
                itemName = 'Реакція Оператора';
                break;
            case "end":
                itemName = 'Завершення';
                break;
            default:
                itemName = option.name;
        }

        return (
            <div className="custom-item">
                <span>{itemName}</span>
            </div>
        );
    }

    const selectedItemTemplate = (option: any) => {
        if (!option) {
            return <p className="opacity-50">Нічого не обрано</p>;
        }

        let itemName: string;

        switch (option.name) {
            case "nameTo":
                itemName = 'Клiент';
                break;
            case "ownerCall":
                itemName = 'Власник дзвінка';
                break;
            case "fullName":
                itemName = 'Оператор';
                break;
            case "direction":
                itemName = 'Тип Активності';
                break;
            case "campaignFullName":
                itemName = 'ID / Назва Кампанii';
                break;
            case "queueFullName":
                itemName = 'ID / Назва Черги';
                break;
            case "status":
                itemName = 'Статус';
                break;
            case "typeChannel":
                itemName = 'Активність';
                break;
            case "callResetName":
                itemName = 'Завершив';
                break;
            case "amdHangup":
                itemName = 'Автовідповідач';
                break;
            case "start":
                itemName = 'Початок';
                break;
            default:
                itemName = option.name;
        }

        return <Tag
            value={itemName} // Отображаем имя опции
            className="custom-chip"
        ></Tag>;
    };

    const onRowReorder = (e: { dragIndex: number; dropIndex: number; }) => {
        let updatedRows = [...selectedFields];
        const draggedRow = updatedRows[e.dragIndex];

        updatedRows.splice(e.dragIndex, 1);
        updatedRows.splice(e.dropIndex, 0, draggedRow);

        updatedRows = updatedRows.map((row, index) => ({
            ...row,
            order: index + 1
        }));

        setSelectedFields(updatedRows);
    }

    const handleDelete = (index: number) => {
        const updatedFields = selectedFields.map((field: any, i: number) =>
            i === index ? {...field, isActive: false} : field
        );
        setSelectedFields(updatedFields);
    }

    const columnGroupItems = () => {
        const filteredData = selectedFields
            ?.filter(
                (field: { isActive: boolean; inSystem: boolean }) =>
                    field.isActive && !field.inSystem
            )
            ?.sort((a: { order: number }, b: { order: number }) => a.order - b.order);

        const normalizeDisplayOrder = (array: any[]) => {
            return array?.map((item, index) => ({
                ...item,
                order: index + 1,
            }));
        };

        const filteredArray = normalizeDisplayOrder(filteredData);

        const nameField = (rowData: any) => {
            let itemName: string;

            switch (rowData.name) {
                case "nameTo":
                    itemName = 'Клiент';
                    break;
                case "ownerCall":
                    itemName = 'Власник дзвінка';
                    break;
                case "fullName":
                    itemName = 'Оператор';
                    break;
                case "direction":
                    itemName = 'Тип Активності';
                    break;
                case "campaignFullName":
                    itemName = 'ID / Назва Кампанii';
                    break;
                case "queueFullName":
                    itemName = 'ID / Назва Черги';
                    break;
                case "status":
                    itemName = 'Статус';
                    break;
                case "typeChannel":
                    itemName = 'Активність';
                    break;
                case "callResetName":
                    itemName = 'Завершив';
                    break;
                case "amdHangup":
                    itemName = 'Автовідповідач';
                    break;
                case "start":
                    itemName = 'Початок';
                    break;
                case "duration":
                    itemName = 'Тривалість розмови';
                    break;
                case "fullCallDuration":
                    itemName = 'Тривалість дзвінка';
                    break;
                case "reactionClient":
                    itemName = 'Реакція Клієнта';
                    break;
                case "reactionOperator":
                    itemName = 'Реакція Оператора';
                    break;
                case "end":
                    itemName = 'Завершення';
                    break;
                default:
                    itemName = rowData.name;
            }

            return <p className="m-0">{itemName}</p>;
        }

        const buttonTemplate = (rowData: any) => {
            const originalIndex = selectedFields.findIndex((field: any) => field.name === rowData.name); // Получаем оригинальный индекс в selectedFields
            return !rowData?.inSystem ? (
                <Button
                    onClick={() => handleDelete(originalIndex)} // Удаляем по индексу из оригинального массива
                    icon="pi pi-times"
                    className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 mb-0 px-0"
                    disabled={!hasUpdatePermission}
                />
            ) : <></>;
        }

        return <DataTable className="w-full table-selected-fields" value={filteredArray} reorderableColumns reorderableRows
                          onRowReorder={onRowReorder}>
            <Column className="custom-column-width-20" rowReorder style={{width: '20px', maxWidth: '20px'}}/>
            <Column field="order" className="text-center" style={{minWidth: '10px', width: '40px', maxWidth: '40px'}}/>
            <Column field="name" body={nameField} style={{minWidth: '50%', maxWidth: '70%', justifyContent: 'flex-start'}}/>
            <Column body={buttonTemplate} style={{width: '20px', padding: 0, maxWidth: '20px'}}/>
        </DataTable>
    };

    const isDisabled = () => {
        return !props.endTime || !props.startTime || !props.filterDateTime || !props.filterEndDateTime;
    };

    return (
        <>
            <div className="table-buttons">
                <div className="text-normal-weight flex align-items-center">
                    <Button
                        label="Мої активності"
                        className={props.activeTab === 'table1' ? 'mr-2 mb-2' : 'mr-2 mb-2'}
                    />
                    <Button className="mb-2" type="button" icon="pi pi-cog"
                            onClick={(e) => {
                                // @ts-ignore
                                op.current.toggle(e);
                                setSelectedFields(userStatisticSortFields?.fields);

                            }}/>
                    <div className="flex field align-items-center max-w-15rem ml-4 desktop-filter">
                        <span className="title pt-2" style={{width: '25px'}}>З*</span>
                        <Calendar className="mt-2" value={props.filterDateTime ? props.filterDateTime : null}
                                  disabled={props.allTimeChecked}
                                  onChange={(e: any) => {
                                      // options.filterCallback(e.value, options.index);
                                      props.setFilterDateTime(e.value)
                                  }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            className="mt-2 ml-2"
                            placeholder="00:00"
                            value={props.startTime ? props.startTime : null}
                            timeOnly
                            disabled={props.allTimeChecked}
                            onChange={(e) => props.setStartTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                    <div className="flex field align-items-center max-w-15rem ml-2 desktop-filter">
                        <span className="title pt-2" style={{width: '35px'}}>По*</span>
                        <Calendar className="mt-2" value={props.filterEndDateTime ? props.filterEndDateTime : null}
                                  onChange={(e: any) => {
                                      props.setFilterEndDateTime(e.value)
                                  }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"
                                  disabled={props.allTimeChecked}
                        />
                        <Calendar
                            appendTo="self"
                            id="icon"
                            className="mt-2 ml-2"
                            value={props.endTime ? props.endTime : null}
                            required
                            placeholder="00:00"
                            timeOnly
                            disabled={props.allTimeChecked}
                            onChange={(e) => props.setEndTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                    <div
                        className="flex align-items-center border-round-lg border-gray-600 border-1 p-2 pt-1 mb-2 ml-2 desktop-filter">
                        <Checkbox className="mr-2" onChange={e => props.setAllTimeChecked(e.checked)}
                                  checked={props.allTimeChecked}/>
                        <label>За весь час</label>
                    </div>
                    <Button disabled={isDisabled()} onClick={() => {props.setHasStartFilter(!props.hasStartFilter);
                        if (props.allTimeChecked === null) {
                            props.setAllTimeChecked(false)
                        }
                    }}
                            icon="pi pi-search" className="mb-2 ml-2 desktop-filter"/>
                    <OverlayPanel ref={op}>
                        <div className="flex flex-column">
                            <div className="mb-3 text-bold">Виберіть стовпці для відображення:</div>
                            <MultiSelect
                                value={selectedFields?.filter((field: any) => !field.inSystem && field?.isActive)}
                                options={selectedFields?.filter((field: { inSystem: any }) => !field.inSystem)}
                                optionLabel="name"
                                onChange={onColumnToggle}
                                className="w-full mb-2 sm:max-w-20rem max-w-14rem"
                                display="chip"
                                filter
                                selectedItemTemplate={selectedItemTemplate}
                                itemTemplate={itemTemplate}
                            />
                            {
                                selectedFields?.length ?
                                    <div className="users-list">
                                        {columnGroupItems()}
                                    </div>
                                    :
                                    <span className="no-users-message">Груп не обрано</span>
                            }


                            <div className="flex justify-content-between field mt-2 mb-0">
                                <Button icon="pi pi-times"
                                        className="p-button p-component p-button-secondary p-button-icon-only"
                                        onClick={(e) => {
                                            // @ts-ignore
                                            op.current.toggle(e)
                                        }}></Button>
                                <Button icon="pi pi-check" onClick={() => {
                                    saveUserFields();
                                    // @ts-ignore
                                    op.current.hide();
                                }}></Button>
                            </div>
                        </div>
                    </OverlayPanel>
                </div>
                <div className="desktop">
                    {hasUpdatePermission &&
                        <Button onClick={() => dispatch(getStatisticExcel(filteredObject, jwtToken?.jwtToken))}
                                label="Експорт таблиці"
                                loading={exportLoading}
                                disabled={!isListLength}
                                icon="pi pi-file-excel"
                                style={{backgroundColor: '#1d6f42'}}
                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                        />}
                    <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри"
                            className="p-button-outlined mr-2 mb-2" onClick={() => {
                        props.resetSorting();

                    }}/>
                </div>
                <div className="mobile">
                    {hasUpdatePermission &&
                        <Button onClick={() => dispatch(getStatisticExcel(filteredObject, jwtToken?.jwtToken))}
                                icon="pi pi-file-excel"
                                disabled={!isListLength}
                                loading={exportLoading}
                                style={{backgroundColor: '#1d6f42'}}
                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                        />}
                    <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2"
                            onClick={() => dispatch(setClearFilters())}/>
                </div>
            </div>
            <div className="flex align-items-center flex-wrap">
                <div className={`col-12 ${props?.profileMenuPin ? 'md:col-6' : `md:col-4`} sm:col-6 field align-items-center mobile-filter mb-1 px-0`}>
                    <span className="title pt-2" style={{minWidth: '35px'}}>З*</span>
                    <Calendar className="mt-2 w-full" value={props.filterDateTime ? props.filterDateTime : null}
                              onChange={(e: any) => {
                                  // options.filterCallback(e.value, options.index);
                                  props.setFilterDateTime(e.value)
                              }} dateFormat="dd/mm/yy" disabled={props.allTimeChecked} placeholder="дд/мм/рр" mask="99/99/9999"/>
                    <Calendar
                        appendTo="self"
                        id="icon"
                        className="mt-2 ml-2 w-full"
                        placeholder="00:00"
                        disabled={props.allTimeChecked}
                        value={props.startTime ? props.startTime : null}
                        timeOnly
                        onChange={(e) => props.setStartTime(e.value)}
                        hourFormat="24"
                    />
                </div>
                <div className={`col-12 ${props?.profileMenuPin ? 'md:col-6' : `md:col-4`} sm:col-6 field align-items-center mobile-filter mb-1 px-0`}>
                    <span className="title pt-2" style={{minWidth: '35px'}}>По*</span>
                    <Calendar className="mt-2 w-full" value={props.filterEndDateTime ? props.filterEndDateTime : null}
                              onChange={(e: any) => {
                                  props.setFilterEndDateTime(e.value)
                              }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" disabled={props.allTimeChecked} mask="99/99/9999"/>
                    <Calendar
                        appendTo="self"
                        id="icon"
                        className="mt-2 ml-2 w-full"
                        value={props.endTime ? props.endTime : null}
                        required
                        placeholder="00:00"
                        disabled={props.allTimeChecked}
                        timeOnly
                        onChange={(e) => props.setEndTime(e.value)}
                        hourFormat="24"
                    />
                </div>
                <div className={`col-12 ${props?.profileMenuPin ? 'md:col-6 md:mb-2' : `md:col-4 md:mb-0`} sm:col-12 mb-2 sm:mb-0 p-0 sm:mb-2 justify-content-between flex sm:justify-content-between md:justify-content-start`}>
                    <div
                        className="align-items-center border-round-lg border-gray-600 border-1 p-2 pt-1 mx-0 md:mx-2 mobile-filter">
                        <Checkbox className="mr-2" onChange={e => props.setAllTimeChecked(e.checked)}
                                  checked={props.allTimeChecked}/>
                        <label>За весь час</label>
                    </div>
                    <Button onClick={() => props.setHasStartFilter(!props.hasStartFilter)}
                            disabled={isDisabled()}
                            icon="pi pi-search" label="Пошук" className="mobile-filter mobile-filter-btn"
                            style={{minWidth: '30px'}}/>
                </div>

            </div>
        </>
    )
}

export default StatisticsListNav;
