export const ARCHIVE_AUDIO_TABLE_TOGGLE = 'ARCHIVE_AUDIO_TABLE_TOGGLE'
export const AUDIO_DIALOG_VISIBLE = 'AUDIO_DIALOG_VISIBLE'
export const AUDIO_CLEAR_FILTERS = 'AUDIO_CLEAR_FILTERS'
export const SELECTED_AUDIO_ID = 'SELECTED_AUDIO_ID'
export const AUDIO_FIELD = 'AUDIO_FIELD'
export const AUDIO_ACD_FIELD = 'AUDIO_ACD_FIELD'
export const SHOW_AUDIO_LOADER = 'SHOW_AUDIO_LOADER'
export const HIDE_AUDIO_LOADER = 'HIDE_AUDIO_LOADER'
export const INITIAL_AUDIO_DATA = 'INITIAL_AUDIO_DATA'
export const GET_AUDIOS = 'GET_AUDIOS'
export const GET_AUDIO = 'GET_AUDIO'
export const SET_AUDIO_ERROR_FIELDS = 'SET_AUDIO_ERROR_FIELDS';
export const GET_AUDIO_PAGINATION = 'GET_AUDIO_PAGINATION'
export const SET_AUDIO_CURRENT_PAGE = 'SET_AUDIO_CURRENT_PAGE'
export const SET_AUDIO_CURRENT_SIZE = 'SET_AUDIO_CURRENT_SIZE'
export const SHOW_AUDIO_DETAIL_LOADING = 'SHOW_AUDIO_DETAIL_LOADING'
export const ADD_AUDIO_USER = 'ADD_AUDIO_USER'
export const SHOW_AUDIO_CREATE_LOADER = 'SHOW_AUDIO_CREATE_LOADER'
export const ADD_AUDIO_BRANCH = 'ADD_AUDIO_BRANCH'
export const DELETE_AUDIO_BRANCH = 'DELETE_AUDIO_BRANCH'
