import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Toast} from "primereact/toast";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {setSelectedRolesIndex} from "../../../redux/actions/actionsRoles";
import {RoleDataSort} from "../../../types/types";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {ProgressBar} from "primereact/progressbar";
import {getNotification} from "../../../redux/api/api";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import StatusesListDetails from "./StatusesListDetails";
import StatusesListNav from "./StatusesListNav";
import StatusListDialog from "./StatusListDialog";
import {setCurrentPage, setCurrentPageSize, setSelectedStatusIndex, setStatusArchiveTableToggle, setStatusDialogVisible, setStatusErrorFields, setStatusListDataSort, setStatusTime} from "../../../redux/actions/actionsStatuses";
import {archiveStatus, getStatusDetails, getStatusesDictionary, getStatusesListWithPaginationFilterAndSorting} from "../../../redux/api/apiStatusesList";
import {Paginator} from "primereact/paginator";
import {MultiSelect} from "primereact/multiselect";
import {Badge} from "primereact/badge";
import {setEditType} from "../../../redux/actions/actions";
import {getUsersManagerUserBranchesData} from "../../../redux/api/apiUsersList";
import BranchDialog from "../../Branch/BranchDialog";

const StatusesList = () => {
    const dispatch = useAppDispatch();
    const companies = useAppSelector(state => state.CompaniesListReducer);
    const clearFilters = useAppSelector(state => state.StatusesListReducer.clearFilters);
    const pagination = useAppSelector(state => state.StatusesListReducer.pagination);
    const paginationPage = useAppSelector(state => state.StatusesListReducer.paginationPage);
    const paginationSize = useAppSelector(state => state.StatusesListReducer.paginationSize);
    const archiveStatusTableToggle = useAppSelector(state => state.StatusesListReducer.archiveStatusTableToggle);
    const loader = useAppSelector(state => state.StatusesListReducer.loading);
    const statusesDictionary = useAppSelector(state => state.StatusesListReducer.statusesDictionary);
    const [unzipNotifyVisible, setUnzipNotifyVisible] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [mobileVisible, setMobileVisible] = useState<boolean>(false);
    // const [cancelNotificationStatusChange, setCancelNotificationStatusChange] = useState<boolean>(false);
    const [showCopyNotification, setShowCopyNotification] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);
    const user = useAppSelector(state => state.UsersReducer.userData);
    const toast = useRef<Toast>(null);
    const [first, setFirst] = useState(0);
    const [data] = useState<any>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [filterStatusesId, setFilterStatusesId] = useState<any>(null);
    const [filterTypeId, setFilterTypeId] = useState<any>(null);
    const [filterStatusName, setFilterStatusName] = useState<any>(null);
    const [filterStatusId, setFilterStatusId] = useState<any>(null)
    const [sortOrder, setSortOrder] = useState<number>(0);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const rolePermissions = checkPermissions(rolesVision, 'Status');
    const [page, setPage] = useState<any>(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const statusesList = useAppSelector(state => state.StatusesListReducer.statusesList) as unknown as any;
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const dataSort = useAppSelector(state => state.StatusesListReducer.dataSort);
    const statusData = useAppSelector(state => state.StatusesListReducer.statusData) as unknown as any;
    let inSystem = statusData?.inSystem;
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [sortData, setSortData] = useState<any>({
        isArchived: !archiveStatusTableToggle,
        pageNumber: page ? page : paginationPage,
        pageSize: paginationSize,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        }
    });
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const {
        create: hasRoleCreatePermission,
        update: hasRoleUpdatePermission,
        delete: hasRoleDeletePermission,
    } = rolePermissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(setCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(setCurrentPageSize(10));
        dispatch(setStatusArchiveTableToggle(true));
        dispatch(getStatusesDictionary(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0;
            scrollableBody.scrollLeft = 0;
        }
    };

    useEffect(() => {
        setSelectedRowData(null);
        let filter: { key: string; value: string }[] = [];
        const dataSort: RoleDataSort = {
            isArchived: !archiveStatusTableToggle,
            pageNumber: page ? page : paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            }
        }

        if (filterStatusesId?.value) {
            filter.push(filterStatusesId);
        }
        if (filterStatusName?.value) {
            filter.push(filterStatusName);
        }
        if (filterTypeId) {
            filterTypeId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterStatusId) {
            dataSort.active = filterStatusId?.value
        }
        if (filter) {
            dataSort['filter'] = filter;
        }
        resetTableHorizontalScroll();
        dispatch(getStatusesListWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
        dispatch(setStatusListDataSort(dataSort));
        setPage(null);
        setSortData(dataSort);
    }, [paginationPage, paginationSize, data, archiveStatusTableToggle, orderBy, isDescFilter, filterStatusesId, filterStatusName, filterStatusId, filterTypeId]);

    useEffect(() => {
        if (activeClick?.item === "/status" && activeClick?.from ==='#/status') {
            dispatch(getStatusesListWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    let cancelNotification = getItem(codes.archive_status_question);
    let copyNotification = getItem(codes.copy_status);
    let unzipNotification = getItem(codes.copy);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    // @ts-ignore
    const copyStatusMessage = <div>{notifications && notifications.copyStatusMessage ? notifications.copyStatusMessage.text : copyNotification ? copyNotification : progressBar}</div>
    const deleteCampaignMessage = <div>{notifications && notifications.archiveStatusMessage ? notifications.archiveStatusMessage.text : cancelNotification ? cancelNotification : progressBar}</div>



    useEffect(() => {
        setFirst(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
    }, [archiveStatusTableToggle]);


    const copyStatusMethod = () => {
        // if (jwtToken?.jwtToken) {
        //     dispatch(copyStatuses(id, jwtToken?.jwtToken, dataSort));
        // }
        dispatch(setStatusArchiveTableToggle(true));
        dispatch(getStatusDetails(id, jwtToken?.jwtToken, true));
        openEditCampaignDialog(id);
    }

    const deleteCampaign = () => {
        if (user.userId) {
            const archiveStatusData = {
                statusId: id,
                value: true
            };
            dispatch(archiveStatus(archiveStatusData, jwtToken?.jwtToken, dataSort));
        }
    };

    const unarchiveCampaign = () => {
        if (user.userId) {
            const archiveStatusData = {
                statusId: id,
                value: false
            };
            dispatch(archiveStatus(archiveStatusData, jwtToken?.jwtToken, dataSort));
        }
    };

    const actionTemplate = (rowData: any) => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        let cancelNotification = getItem(codes.cancel);
        const deletionToastShow = (campaignId: number) => {
            if (!cancelNotification) {
                dispatch(getNotification(user.userId, 153, user));
            }
            setVisible(true);
            setId(campaignId);
        }

        const copyToastShow = (campaignId: number) => {
            if (!copyNotification) {
                dispatch(getNotification(user.userId, 199, user));
            }
            setShowCopyNotification(true);
            setId(campaignId);
        }

        const unzipToastShow = (campaignId: number) => {
            if (!unzipNotification) {
                dispatch(getNotification(user.userId, 107, user));
            }
            setUnzipNotifyVisible(true);
            setId(campaignId);
        }
        return (
            <div className="flex">
                <div className="controls-buttons">
                    {hasRoleCreatePermission && (<Button className="p-button-text p-button-icon" onClick={() => copyToastShow(rowData.id)} icon="pi pi-copy"></Button>)}
                </div>
                {archiveStatusTableToggle && hasRoleDeletePermission && <Button className="p-button-text" disabled={rowData.inSystem} icon="pi pi-trash" onClick={() => deletionToastShow(rowData.id)}></Button>}
                {!archiveStatusTableToggle && hasRoleDeletePermission && <Button className="p-button-text" icon="pi pi-folder-open" onClick={() => unzipToastShow(rowData.id)}></Button>}
            </div>
        );
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""></Button>
    }

    const dataTableRef = useRef<any>(null);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
        setFirst(0);
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setCurrentPage(event.page + 1));
        dispatch(setCurrentPageSize(event.rows));
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'id':
                    newOrderBy = 'Id';
                    break;
                case 'statusName':
                    newOrderBy = 'StatusName';
                    break;
                case 'typeId':
                    newOrderBy = 'TypeId';
                    break;
                case 'active':
                    newOrderBy = 'Active';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };


    const customFilter = (event?: any) => {
        if (event) {
            const {id, statusName, active, typeId} = event.filters;
            setFilterStatusesId(id ? {key: 'Id', value: id.constraints[0].value} : null);
            setFilterStatusName(statusName ? {key: 'StatusName', value: statusName.constraints[0].value} : null);
            setFilterStatusId(active ? {key: 'Active', value: active.value ? active.value.code : null} : null);
            if (typeId.value) {
                setFilterTypeId(typeId.value.map((item: any) => ({key: 'TypeId', value: String(item)})));
            } else {
                setFilterTypeId(null);
            }
        } else {
            setFilterStatusesId(null);
            setFilterTypeId(null);
            setFilterStatusName(null);
            setFilterStatusId(null);
            setOrderBy(null);
            setIsDescFilter(true);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center sticky top-0" onClick={() => customSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const openEditCampaignDialog = (id?: number) => {
        dispatch(setSelectedRolesIndex(id || statusData?.id || selectedRowData));
        dispatch(setStatusDialogVisible(true));
        setSelectedRowData(null);
        dispatch(setStatusTime(null));
        if (hasRoleUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const dialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    openEditCampaignDialog();
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': archiveStatusTableToggle && hasRoleUpdatePermission && !inSystem,
                        'pi pi-eye': !archiveStatusTableToggle || !hasRoleUpdatePermission || inSystem
                    })}></span></Button>
            </div>
        </>
    }

    const activeDict = [
        {name: 'Активна', code: true},
        {name: 'Неактивна', code: false},
    ];

    const activeFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Виберіть:</div>
                <Dropdown
                    value={options.value}
                    options={activeDict}
                    // itemTemplate={activeItemTemplate}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    optionLabel="name"
                    className="p-column-filter"
                />
            </>
        );
    };

    const workStateFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    options={statusesDictionary && statusesDictionary.map((item: { dictionaryId: any; }) => item.dictionaryId)}
                    itemTemplate={workStateItemTemplate}
                    selectedItemTemplate={selectedWorkStateTemplate}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const selectedWorkStateTemplate = (option: any) => {
        let typeName = statusesDictionary.map((item: { dictionaryId: any; description: any; }) => {
            if (item.dictionaryId === option) {
                return item.description
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName},
                </span>
            );
        }

        return "Оберіть роль";
    }

    const workStateItemTemplate = (option: any) => {
        let typeName = statusesDictionary.map((item: { dictionaryId: any; description: any; }) => {
            if (item.dictionaryId === option) {
                return item.description
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            </div>
        );
    }

    const typeBodyTemplate = (rowData: any) => {
        let typeName = statusesDictionary.map((item: any) => {
            if (item.dictionaryId === rowData.typeId) {
                return item.description
            }
        })
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            </React.Fragment>
        );
    };

    const renderStatusColumn = (rowData: any) => {
        const replaceStatusName = rowData?.statusName?.replace(/Copy.*/, 'Copy...');
        return (
            <Badge value={replaceStatusName} className={`p-badge text-white customer-badge text-xs p-0 mr-4 px-2`} style={{borderRadius: '6px', backgroundColor: `${rowData.statusColor}`, minWidth: '180px'}}/>
        );
    };

    return (
        <div className="layout-dashboard">
            <Toast ref={toast}/>
            <div className="">
                <div className="flex col-12 md:col-12 flex-wrap p-0">
                    {/*<div className="card widget-table">*/}
                    <div className={classNames('card widget-table col-12 transition-duration-300 monitoring-table align-self-start m-0 mr-0', {'': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                        <StatusesListNav handleReset={handleReset}/>
                        <DataTable
                            ref={dataTableRef}
                            scrollable
                            scrollHeight={(selectedRowData) && !isMobile() ? '33vh' : '70vh'}
                            className="p-datatable-customers"
                            value={archiveStatusTableToggle ?  statusesList : statusesList}
                            dataKey="id"
                            filters={clearFilters}
                            rowHover={false}
                            selectionMode="single"
                            selection={selectedRowData}
                            onSort={customSort}
                            onFilter={customFilter}
                            emptyMessage="Нічого не знайдено"
                            responsiveLayout='scroll'
                            onRowClick={(e) => {
                                setSelectedRowData(e.data.id);
                                dispatch(getStatusDetails(e.data.id, jwtToken?.jwtToken));
                                dispatch(setSelectedStatusIndex(e.data.id));
                                dispatch(setStatusErrorFields(null));
                                setMobileVisible(true);
                                if (isMobile()) {
                                    setMobileVisible(true);
                                }
                            }}
                            loading={loader}
                            rowClassName={(rowData: any) =>
                                rowData?.id === selectedRowData ? 'p-highlight' : ''
                            }
                        >
                            <Column
                                field="id"
                                filterField="id"
                                header={renderHeader('ID', 'Id')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть ID:"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'CampaignId'})}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '90px'}}
                            />
                            <Column
                                field="statusName"
                                filterField="statusName"
                                header={renderHeader('Назва', 'StatusName')}
                                className={classNames('', {'active-header-column': orderBy === 'StatusName'})}
                                filterPlaceholder="Введіть назву кампанії"
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer',   width: (hasRoleCreatePermission || hasRoleDeletePermission) ? '30%' : '50%', minWidth: '150px'}}
                            />
                            <Column
                                field="statusName"
                                filterField="statusName"
                                body={renderStatusColumn}
                                header={'Зовнішній вигляд'}
                                style={{cursor: 'pointer', minWidth: '230px'}}
                            />
                            <Column
                                field="typeId"
                                filterField="typeId"
                                header={renderHeader('Тип статусу', 'TypeId')}
                                className={classNames('', {'active-header-column': orderBy === 'TypeId'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={typeBodyTemplate}
                                // body={data => data?.typeId ? 'Робочий' : 'Неробочий'}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={workStateFilterTemplate}
                                style={{cursor: 'pointer', width: '20%', minWidth: '150px'}}
                            />
                            <Column
                                field="active"
                                filterField="active"
                                header={renderHeader('Стан', 'Active')}
                                className={classNames('', {'active-header-column': orderBy === 'Active'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={data => data?.active ? 'Активна' : 'Не активна'}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={activeFilterTemplate}
                                style={{cursor: 'pointer', width: '20%', minWidth: '150px'}}
                            />
                            {(hasRoleCreatePermission || hasRoleDeletePermission) && (<Column header="Дія" style={{cursor: 'pointer', width: '15%', minWidth: '100px'}} body={actionTemplate}></Column>)}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={pagination.totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                        />
                    </div>
                    {selectedRowData && companies && (
                        <>
                            {windowWidth > 1248 && <div className={classNames('card widget-table col-12 md:col-12 mt-4 align-self-start sticky top-0')}>
                                <StatusesListDetails setSelectedRowData={setSelectedRowData} selectedRowData={selectedRowData} windowWidth={windowWidth}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={mobileVisible} header={dialogHeader} className="monitoring-info" onHide={() => setMobileVisible(false)}>
                                    <StatusesListDetails/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                    <StatusListDialog/>
                    <ConfirmDialog visible={showCopyNotification} onHide={() => setShowCopyNotification(false)} message={copyStatusMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => copyStatusMethod()}/>
                    <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={deleteCampaignMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteCampaign()}/>
                    <ConfirmDialog visible={unzipNotifyVisible} onHide={() => setUnzipNotifyVisible(false)} message={'Статус буде розпаковано ! Розпакувати статус ?'}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => unarchiveCampaign()}/>
                </div>
            </div>
            <BranchDialog/>
        </div>
    );
};

export default StatusesList;
