import React, {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {classNames} from "primereact/utils";
import {InputSwitch} from "primereact/inputswitch";
import {Divider} from "primereact/divider";
import TemplateDemo from "../UploadAudio";
import {addAudioBranch, deleteAudioBranch, setAudioField} from "../../../../redux/actions/actionsAudio";
import {Checkbox} from "primereact/checkbox";
import {setEditType} from "../../../../redux/actions/actions";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {getBranchDetailById} from "../../../../redux/api/apiBranchList";
import {deleteQueueBranch} from "../../../../redux/actions/actionsQueue";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";

const AudioDialogGeneralSettings = (props: any) => {
    const dispatch = useAppDispatch();
    const audioData = useAppSelector(state => state.AudioListReducer.audioData);
    const errorFields = useAppSelector(state => state.AudioListReducer.errorFields);
    const nameField = useRef(null);
    const editType = useAppSelector(state => state.UsersReducer.editType);
    const archiveTableToggle = useAppSelector(state => state.AudioListReducer.archiveAudioTableToggle)
    const [RingbackChanged, setRingbackChanged] = useState(false);
    const [holdChanged, setHoldChanged] = useState(false);
    const createLoader = useAppSelector(state => state.AudioListReducer.createLoader);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const branchPermissions = checkPermissions(rolesVision, 'Branch');
    const branchDetailsLoader = useAppSelector(state => state.BranchesListReducer.branchDetailsLoader);
    const [currentUserId, setCurrentUserId] = useState<any>(null);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    useEffect(() => {
    }, []);

    const {
        visible: hasBranchVisionPermission,
        update: hasBranchUpdatePermission
    } = branchPermissions;

    const scrollIntoNameView = () => {
        // @ts-ignore
        nameField?.current?.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        if (!audioData.name && errorFields?.name) {
            scrollIntoNameView();
        }
        if (errorFields?.nameUniq) {
            scrollIntoNameView();
        }
    }, [errorFields, audioData]);

    useEffect(() => {
        if (!audioData.isActive) {
            if (holdChanged) {
                dispatch(setAudioField({section: 'audioData', fieldName: 'isHold', value: false}));
            }
            if (RingbackChanged) {
                dispatch(setAudioField({section: 'audioData', fieldName: 'isRingback', value: false}));
            }
        }
    }, [audioData.isActive]);

    useEffect(() => {
        if (holdChanged) {
            setHoldChanged(false);
        }
        if (RingbackChanged) {
            setRingbackChanged(false);
        }
    }, [createLoader]);

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const changeAudioBranches = (e: any) => {
        const filteredEValue = e.value?.filter((item: { id: number; }) => item.id);
        const existingIds = new Set(filteredEValue.map((item: { id: number; }) => item.id));
        const skillGroupData = new Set(formattedBranches.map((item: { id: number; }) => item.id));
        const newItems = audioData && audioData.audioBranches
            ? audioData.audioBranches.filter((item: { id: number; }) =>
                !existingIds.has(item.id) && !skillGroupData.has(item.id))
            : [];
        const updatedEValue = [...filteredEValue, ...newItems];

        dispatch(addAudioBranch({ audioId: audioData?.audioId, id: updatedEValue }));
    }

    const branchesValue = audioData?.audioBranches?.map((companiesItem: any) => {
        const user = formattedBranches.find((userItem: any) => userItem.id === companiesItem.id);
        const {audioId, isMainIntegrationUrl, ...userWithoutCampaignId} = companiesItem;
        return {
            ...userWithoutCampaignId,
            description: user?.description,
            id: user?.id,
            isArchived: user?.isArchived,
            name: user?.name,
            label: user?.label,
            ownerId: user?.ownerId,
            ownerName: user?.ownerName,
            parentId: user?.parentId,
            parentName: user?.parentName,
            userCount: user?.userCount,
            integrationURLId: user?.integrationURLId,
            isMainIntegrationUrl: user?.isMainIntegrationUrl,
            mainBranchId: user?.mainBranchId
        }
    });

    const branchItems = audioData?.audioBranches?.map((audioItem: any) => {
        return formattedBranches?.map((branchItem: any, index: number) => {
            if (audioItem.id === branchItem.id) {
                return (
                    <div key={index} className="flex align-items-center mb-2">
                        <div className="field col-10 md:col-10 flex align-items-center m-0">
                            {hasBranchVisionPermission && <Button
                                className="max-w-4rem p-button-secondary mr-3"
                                style={{minWidth: '40px'}}
                                loading={branchDetailsLoader && currentUserId === branchItem.id}
                                disabled={!archiveTableToggle || branchDetailsLoader}
                                icon={archiveTableToggle && hasBranchUpdatePermission ? 'pi pi-pencil' : !archiveTableToggle || !hasBranchUpdatePermission ? 'pi pi-eye' : ''}
                                onClick={() => {
                                    setCurrentUserId(branchItem.id);
                                    dispatch(getBranchDetailById(branchItem.id, jwtToken?.jwtToken, true));
                                    if (hasBranchUpdatePermission) {
                                        dispatch(setEditType(true));
                                    } else {
                                        dispatch(setEditType(false));
                                    }
                                }}/>}
                            {branchItem.label}
                        </div>
                        <div className="field col-2 md:col-2 flex align-items-center justify-content-end m-0 p-0">
                            <Button name={branchItem.id}
                                    onClick={() => {
                                        dispatch(deleteAudioBranch(branchItem.id));
                                    }}
                                    icon="pi pi-times"
                                    className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 m-0"
                                    disabled={!archiveTableToggle || !editType}
                            />
                        </div>
                    </div>
                )
            }
        })
    });

    return (
        <div className="general-settings">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-6 mb-0" ref={nameField}>
                    <label htmlFor="name"
                           className={classNames('', {'p-error': errorFields?.audioName || errorFields?.nameUniq})}>Назва
                        аудіо *</label>
                    <InputText
                        id="name"
                        value={audioData?.audioName || ''}
                        maxLength={150}
                        className={classNames('', {'p-invalid': errorFields?.audioName || errorFields?.nameUniq})}
                        onChange={e => {
                            dispatch(setAudioField({section: 'audioData', fieldName: 'audioName', value: e.target.value}));
                        }}
                        disabled={!archiveTableToggle || !editType || audioData?.inSystem}
                    />
                    {errorFields?.nameUniq && <div className="col-12 md:col-12 pt-2 px-0 pb-0"><span
                        className="title p-error text-sm">{errorFields?.nameUniq}*</span></div>}
                </div>
                <div className="field col-12 md:col-1 m-0 p-0"></div>
                <div className="field col-12 md:col-2 m-0 p-0 pl-2">
                    <label className="">По замовчуванню для:</label>
                    <div className="flex justify-content-between col-12 md:col-12 p-0">
                        <p className="mb-1">HOLD</p>
                        <Checkbox
                            disabled={!archiveTableToggle || !editType || (audioData?.isHold && !holdChanged) || !audioData?.isActive}
                            checked={audioData?.isHold} onChange={e => {
                            setHoldChanged(true)
                            dispatch(setAudioField({section: 'audioData', fieldName: 'isHold', value: e.checked}));
                        }}/>
                    </div>
                    <div className="flex justify-content-between col-12 md:col-12 p-0">
                        <p className="mb-0">RINGBACK</p>
                        <Checkbox
                            disabled={!archiveTableToggle || !editType || !audioData?.isActive || (audioData?.isRingback && !RingbackChanged)}
                            checked={audioData?.isRingback} onChange={e => {
                            setRingbackChanged(true);
                            dispatch(setAudioField({section: 'audioData', fieldName: 'isRingback', value: e.checked}));
                        }}/>
                    </div>
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="description"
                           className={classNames('', {'p-error': errorFields?.description})}>Коментар*</label>
                    <InputTextarea
                        id="description"
                        value={audioData?.description || ''}
                        onChange={e => {
                            dispatch(setAudioField({section: 'audioData', fieldName: 'description', value: e.target.value}));
                        }}
                        className={classNames('', {'p-invalid': errorFields?.description})}
                        autoResize
                        rows={3}
                        maxLength={500}
                        disabled={!archiveTableToggle || !editType || audioData?.inSystem}
                    />
                </div>
                <Divider className="mx-2"/>
                <div className="field col-12 md:col-12 mb-0">
                    <TemplateDemo audioSrc={props.audioSrc} setAudioSrc={props.setAudioSrc}/>
                </div>
                <Divider className="mx-2 mt-0"/>
                <div className="field col-12 md:col-4 flex flex-column">
                    <label htmlFor="name" className={classNames('', {'p-error': errorFields?.branchId && !audioData?.branchId})}>Бранч *</label>
                    <MultiSelect
                        appendTo="self"
                        value={branchesValue}
                        options={formattedBranches}
                        className={classNames('', {'p-invalid': errorFields?.branchId})}
                        onChange={(e) => {
                            changeAudioBranches(e);
                        }}
                        placeholder="Оберіть бранч"
                        filter
                        optionLabel="label"
                        selectedItemsLabel={branchesValue ? `Обрано: ${branchesValue?.length}` : ""}
                        maxSelectedLabels={0}
                        scrollHeight="250px"
                        disabled={!archiveTableToggle || !editType}
                    />
                    {
                        branchesValue?.length ?
                            <div className="users-list mt-3">
                                {branchDetailsLoader && <div className="bg-loading-animation"><i className="pi pi-spin pi-spinner mr-2" style={{fontSize: '2rem'}}></i></div>}
                                {branchItems}
                            </div>
                            :
                            <>

                                {
                                    errorFields?.branchId ? <span className={classNames('', {'block title p-error text-sm mt-4': errorFields?.branchId})}>Нічого не вибрано</span> :
                                        <span className={classNames('no-users-message mt-4')}>Нічого не вибрано</span>
                                }
                            </>

                    }
                </div>
                <Divider className="mx-2"/>
                <div className="field col-12 md:col-12">
                    <label htmlFor="name" className="pl-2">Стан</label>
                    <div className="field-radiobutton my-4 pl-1">
                        <label htmlFor="name" className="mr-2 w-6rem">Не активна</label>
                        <InputSwitch
                            disabled={!archiveTableToggle || !editType || audioData?.inSystem}
                            className="mr-3"
                            checked={audioData.isActive} onChange={(e) => {
                            dispatch(setAudioField({section: 'audioData', fieldName: 'isActive', value: e.value}));
                        }}/>
                        <label htmlFor="icon">Активна</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AudioDialogGeneralSettings;
