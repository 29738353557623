import {DataTable} from "primereact/datatable";
import React, {useRef} from "react";
import {Column} from "primereact/column";
import {useAppSelector} from "../../../../redux/hooks";

const UserDialogCampaign = (props: any) => {
    const dataTableRef = useRef<DataTable | any>(null);
    // @ts-ignore
    const userData = useAppSelector(state => state.UsersListReducer.userData);
    const userInInfo = userData?.user?.userInInfo;

    const queueIdWithName = (rowData: any) => {
        if (rowData?.queue) {
            return (
            <ul className="list-none px-0 m-0">
                {rowData?.queue?.map((item: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
                    <li className="" key={index}>{item}</li>
                ))}
            </ul>
            );
        }
        return null;
    }

    const campaignIdWithName = (rowData: any) => {
        if (rowData?.campaign) {
            return (
                <ul className="list-none px-0 m-0">
                    {rowData?.campaign?.map((item: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
                        <li className="" key={index}>{item}</li>
                    ))}
                </ul>
            );
        }

        return null;
    }

    const qualityGroup = (rowData: any) => {
        if (rowData?.skillGroupName) {
            return (
                <p>{rowData?.skillGroupId} {rowData?.skillGroupName}</p>
            );
        }

        return null;
    }

    return (
        <DataTable value={userInInfo}
                   scrollable={!props?.scrollable}
                   ref={dataTableRef}
                   className='qualGroup w-full'
                   emptyMessage="Нічого не знайдено"
        >
            <Column
                style={{cursor: 'pointer', width: '10%', minWidth: '50px'}}
                field="userLevel"
                className='border-bottom-1'
                sortable={false}
                header="Квал рiвень"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
            />
            <Column
                style={{cursor: 'pointer', width: '30%', minWidth: '100px'}}
                className='border-bottom-1'
                field="companyName"
                header="Квал Группа"
                showFilterMatchModes={false}
                sortable={false}
                body={qualityGroup}
                showFilterMenuOptions={false}
            />
            <Column
                style={{cursor: 'pointer', width: '30%', minWidth: '100px'}}
                field="qualGroup"
                className='border-bottom-1'
                sortable={false}
                header="ID/ Назва Черги"
                body={queueIdWithName}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
            />
            <Column
                style={{cursor: 'pointer', width: '30%', minWidth: '100px'}}
                field="qualGroup"
                className='border-bottom-1'
                sortable={false}
                header="ID/ Назва Кампанii"
                body={campaignIdWithName}
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
            />
        </DataTable>
    );
}

export default UserDialogCampaign;
