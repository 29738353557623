import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {MultiSelect} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import CompaniesListNav from "../СompaniesListNav";
import CompaniesListDialog from "../Dialog";
import {setArchiveTableToggle, setCompanyField, setCurrentPage, setCurrentPageSize, setDialogVisible, setSelectedCompanyIndex, updateStateCampaignSignal} from "redux/actions/actions";
import {copyCampaign, deleteCompanies, getCampaignContactsWithPagination, getCampaignDirectoriesCampaignType, getCampaignId, getCompaniesWithPaginationFilterAndSorting, getNotification, getUsers, updateStateCampaign} from "redux/api/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {getItem} from "../../../redux/cache/cacheService";
import {ProgressBar} from "primereact/progressbar";
import {codes} from "../../../redux/notificationCodes";
import * as signalR from "@microsoft/signalr";
import {HubConnectionBuilder} from '@microsoft/signalr';
import {MVP, WSS_URL} from "../../../redux/config";
import {Paginator} from "primereact/paginator";
import {GET_COMPANIES} from "../../../redux/types/types";
import {classNames} from "primereact/utils";
import {DataSort, DecodedToken} from "../../../types/types";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {Dialog} from "primereact/dialog";
import CompaniesListDetails from "./CompaniesListDetails";
import {jwtDecode} from "jwt-decode";
import {getUsersManagerUserBranchesData} from "../../../redux/api/apiUsersList";
const CompaniesList = () => {
    const dispatch = useAppDispatch();
    // @ts-ignore
    const clearFilters = useAppSelector(state => state.CompaniesListReducer.clearFilters);
    // @ts-ignore
    const dialogVisible = useAppSelector(state => state.CompaniesListReducer.dialogVisible);
    // @ts-ignore
    const companiesList = useAppSelector(state => state.CompaniesListReducer.companiesList);
    const companies = useAppSelector(state => state.CompaniesListReducer);
    // @ts-ignore
    const pagination = useAppSelector(state => state.CompaniesListReducer.pagination);
    // @ts-ignore
    const paginationPage = useAppSelector(state => state.CompaniesListReducer.paginationPage);
    // @ts-ignore
    const paginationSize = useAppSelector(state => state.CompaniesListReducer.paginationSize);
    // @ts-ignore
    const companiesListArchived = useAppSelector(state => state.CompaniesListReducer.companiesListArchived);
    // @ts-ignore
    const companiesDirectoryCampaignType = useAppSelector(state => state.CompaniesListReducer.companiesDirectoryCampaignType);
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle);
    // @ts-ignore
    const loader = useAppSelector(state => state.CompaniesListReducer.loading);
    // @ts-ignore
    const campaignContactsPageSize = useAppSelector(state => state.CompaniesListReducer.campaignContactsPageSize);
    const [visible, setVisible] = useState<boolean>(false);
    const [filterBranchId, setFilterBranchId] = useState<any>(null);
    const [mobileVisible, setMobileVisible] = useState<boolean>(false);
    const [cancelNotificationStatusChange, setCancelNotificationStatusChange] = useState<boolean>(false);
    const [launchNotificationStatusChange, setLaunchNotificationStatusChange] = useState<boolean>(false);
    const [suspendNotificationStatusChange, setSuspendNotificationStatusChange] = useState<boolean>(false);
    const [showCopyNotification, setShowCopyNotification] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);
    const [campaignId, setCampaignId] = useState<number>(0);
    const user = useAppSelector(state => state.UsersReducer.userData);
    const toast = useRef<Toast>(null);
    const [first, setFirst] = useState(0);
    const [data, setData] = useState<any>(null);
    const [allCampaigns, setAllCampaigns] = useState<any>([]);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [filterCampaignId, setFilterCampaignId] = useState<any>(null);
    const [filterCampaignName, setFilterCampaignName] = useState<any>(null);
    const [filterCampaignTypeId, setFilterCampaignTypeId] = useState<any>(null);
    const [filterStatusId, setFilterStatusId] = useState<any>(null);
    const [filterIsGeneral, setFilterIsGeneral] = useState<any>(null)
    const [sortOrder, setSortOrder] = useState<number>(0);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');
    const [page, setPage] = useState<any>(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData);
    const reconnectSession = useAppSelector(state => state.WebRTCReducer.reconnectSession);
    const [dialerHubConnection, setDialerHubConnection] = useState(null) as any;
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const branches = useAppSelector(state => state.UsersListReducer.branches);
    let decodedToken: DecodedToken;
    if (jwtToken?.jwtToken) {
        decodedToken = jwtDecode(jwtToken?.jwtToken);
    }

    const [sortData, setSortData] = useState<any>({
        isArchived: !archiveTableToggle,
        pageNumber: page ? page : paginationPage,
        pageSize: paginationSize,
        sort: {
            orderBy: orderBy ? orderBy : "CampaignId",
            isDesc: isDescFilter
        }
    });
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const {
        create: hasDialerCreatePermission,
        update: hasDialerUpdatePermission,
        delete: hasDialerDeletePermission,
    } = dialerPermissions;

    const statusesDict = [
        {name: 'DRAFT', code: 1},
        {name: 'IDLE', code: 2},
        {name: 'ACTIVE', code: 3},
        {name: 'PAUSE', code: 4},
        {name: 'CANCEL', code: 5},
        {name: 'DONE', code: 6},
    ];

    const isGeneral = [
        {name: 'Транк кампанії', value: true},
        {name: 'Транк оператора', value: false},
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(setCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
        dispatch(getCampaignDirectoriesCampaignType(jwtToken?.jwtToken));
        dispatch(setCurrentPageSize(10));
        dispatch(setArchiveTableToggle(true));
    }, []);

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0; // Сброс прокрутки
            scrollableBody.scrollLeft = 0;
        }
    };

    useEffect(() => {
        let filter: { key: string; value: string }[] = [];
        const dataSort: DataSort = {
            isArchived: !archiveTableToggle,
            pageNumber: page ? page : paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "CampaignId",
                isDesc: isDescFilter
            }
        }
        if (filterCampaignId?.value) {
            filter.push(filterCampaignId);
        }
        if (filterCampaignTypeId) {
            filterCampaignTypeId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterStatusId) {
            filterStatusId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterIsGeneral) {
            filterIsGeneral.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterBranchId) {
            filterBranchId.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterCampaignName?.value) {
            filter.push(filterCampaignName);
        }

        if (filter) {
            dataSort['filter'] = filter;
        }
        dispatch(getCompaniesWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
        setSortData(dataSort);
        setPage(null);
        resetTableHorizontalScroll();
    }, [paginationPage, paginationSize, data, archiveTableToggle, orderBy, isDescFilter, filterCampaignId, filterCampaignName, filterCampaignTypeId]);

    useEffect(() => {
        if (activeClick?.item === '/' && activeClick?.from ==='#/') {
            dispatch(getCompaniesWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    let cancelNotification = getItem(codes.cancel);
    let launchNotification = getItem(codes.launch);
    let suspendNotification = getItem(codes.suspend);
    let copyNotification = getItem(codes.copy);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    // @ts-ignore
    const launchMessage = <div>{companies && companies.notificationLaunch ? companies.notificationLaunch.text : launchNotification ? launchNotification : progressBar}</div>
    // @ts-ignore
    const cancelMessage = <div>{companies && companies.notificationCancel ? companies.notificationCancel.text : cancelNotification ? cancelNotification : progressBar}</div>
    // @ts-ignore
    const suspendMessage = <div>{companies && companies.notificationSuspend ? companies.notificationSuspend.text : suspendNotification ? suspendNotification : progressBar}</div>
    // @ts-ignore
    const copyCampaignMessage = <div>{companies && companies.notificationCopy ? companies.notificationCopy.text : copyNotification ? copyNotification : progressBar}</div>
    // @ts-ignore
    const deleteCampaignMessage = <div>{companies && companies.notificationCancel ? companies.notificationCancel.text : cancelNotification ? cancelNotification : progressBar}</div>


    function startConnection() {
        const connection = new HubConnectionBuilder()
            .withUrl(`${WSS_URL}DialerHub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => jwtToken?.jwtToken || ""
            })
            .withAutomaticReconnect()
            .build();

        connection.serverTimeoutInMilliseconds = 60000;

        connection.onreconnected(() => {
            console.log(`[${new Date().toISOString()}] Information: WebSocket reconnected to ${WSS_URL}DialerHub`);
            connection.invoke("UserConnected", decodedToken[`x-access-userid`]).catch(err => console.error(err));
        });

        connection.start()
            .then(() => {
                connection.on('StateCampaign', StateCampaign => {
                    setData(JSON.parse(StateCampaign));
                });
                connection.on('AllCampaigns', AllCampaigns => {
                    setAllCampaigns(JSON.parse(AllCampaigns));
                });
                connection.invoke("UserConnected", decodedToken[`x-access-userid`]);
            })
            .catch(() => {
                setTimeout(startConnection, 5000);
            });
            setDialerHubConnection(connection);
        return connection;
    }

    let connection: any;

    useEffect(() => {
        if (!reconnectSession && !dialogVisible) {
            connection = startConnection();
            return () => {
                connection.stop();
            };
        } else {
            if (dialerHubConnection && dialerHubConnection.state === 'Disconnecting') {
                dialerHubConnection.stop();
                connection = startConnection();
            }
        }
    }, [reconnectSession, dialogVisible]);

    useEffect(() => {
        if (data) {
            let newStatusCampaign = companiesList?.map((campaign: any) => {
                if (campaign.campaignId === data.CampaignId) {
                    return {...campaign, statusId: data.StatusId}
                }
                return campaign;
            });
            if (data?.CampaignId === selectedRowData) {
                dispatch(getCampaignId(data?.CampaignId, jwtToken?.jwtToken));
            }
            dispatch(updateStateCampaignSignal(newStatusCampaign));
        }
    }, [data]);

    useEffect(() => {
        setSelectedRowData(null);
    }, [archiveTableToggle, paginationPage, paginationSize]);

    useEffect(() => {
        const matchingCampaignDetails = allCampaigns?.find((c: any) => c.CampaignId === selectedRowData);
        if (matchingCampaignDetails) {
            const fieldsToUpdate = [
                'activeOperators', 'isArchived', 'countUsers', 'isDoneContactCount',
                'countContacts', 'progressContacts', 'progressCampaign', 'calledContactCount',
                'name', 'description', 'statusId', 'campaignTypeId', 'operatorStrategyTypeId',
                'priority', 'schedulerId', 'employmentOperators', 'wellComplet', 'firstCallOperator'
            ];
            const fieldsToSchedulerUpdate = [
                'typeEventStart',
                'typeScheduler',
                'startDate',
                'startTime',
                'endDate',
                'endTime'
            ];
            fieldsToUpdate.forEach(fieldName => {
                dispatch(setCompanyField({section: 'campaign', fieldName, value: matchingCampaignDetails[fieldName.charAt(0).toUpperCase() + fieldName.slice(1)]}));
            });
            fieldsToSchedulerUpdate.forEach(fieldName => {
                dispatch(setCompanyField({section: 'scheduler', fieldName, value: matchingCampaignDetails[fieldName.charAt(0).toUpperCase() + fieldName.slice(1)]}));
            });
        }

        let newStatusCampaign = companiesList?.map((campaign: any) => {
            const {campaignId} = campaign;
            const matchingCampaign = allCampaigns?.find((c: any) => c.CampaignId === campaignId);
            if (matchingCampaign) {
                const {
                    ActiveOperators,
                    IsArchived,
                    CountUsers,
                    CountContacts,
                    IsDoneContactCount,
                    ProgressContacts,
                    ProgressCampaign,
                    CalledContactCount,
                    Name,
                    Description,
                    StatusId,
                    CampaignTypeId,
                    OperatorStrategyTypeId,
                    Priority,
                    SchedulerId,
                    EmploymentOperators,
                    WellComplet,
                    FirstCallOperator
                } = matchingCampaign;
                return {
                    ...campaign,
                    activeOperators: ActiveOperators,
                    isArchived: IsArchived,
                    countUsers: CountUsers,
                    countContacts: CountContacts,
                    isDoneContactCount: IsDoneContactCount,
                    progressContacts: ProgressContacts,
                    progressCampaign: ProgressCampaign,
                    calledContactCount: CalledContactCount,
                    name: Name,
                    description: Description,
                    statusId: StatusId,
                    campaignTypeId: CampaignTypeId,
                    operatorStrategyTypeId: OperatorStrategyTypeId,
                    priority: Priority,
                    schedulerId: SchedulerId,
                    employmentOperators: EmploymentOperators,
                    wellComplet: WellComplet,
                    firstCallOperator: FirstCallOperator
                };
            }
            return campaign;
        });

        dispatch({type: GET_COMPANIES, payload: newStatusCampaign});
    }, [allCampaigns]);

    useEffect(() => {
        setFirst(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
    }, [archiveTableToggle]);

    function calculatePercent(rowData: any) {
        const {calledContactCount, countContacts} = rowData;
        const percent = countContacts === 0 ? 0 : Math.round(calledContactCount * 100 / countContacts);
        return {...rowData, percent};
    }


    const companiesListArchivedWithPercent = companiesListArchived?.map(calculatePercent);
    let companiesListWithPercent = companiesList?.map(calculatePercent);

    const typeBodyTemplate = (rowData: any) => {
        let typeName = companiesDirectoryCampaignType?.map((item: any) => {
            if (item.dictionaryId === rowData.campaignTypeId) {
                return item.name
            }
        })
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            </React.Fragment>
        );
    };

    const isGeneralBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData?.isGeneralTrunk ? 'Транк Кампанії' : 'Транк Оператора'}
                </span>
            </React.Fragment>
        );
    };

    const updateCampaign = (campaignData: any, statusId: number) => {
        campaignData.statusId = statusId;
        dispatch(updateStateCampaign(campaignData, jwtToken?.jwtToken))
    };

    const StateCampaign = {
        "campaignId": 0,
        "statusId": null,
        "userId": user?.userId
    }

    const cancelCampaign = () => {
        StateCampaign.campaignId = campaignId;
        updateCampaign(StateCampaign, 5);
    }

    const launchCampaign = () => {
        StateCampaign.campaignId = campaignId;
        updateCampaign(StateCampaign, 2);
    }

    const suspendCampaign = () => {
        StateCampaign.campaignId = campaignId;
        updateCampaign(StateCampaign, 4);
    }

    const copyCampaignMethod = () => {
        if (user.userId) {
            dispatch(copyCampaign(id, jwtToken?.jwtToken, sortData));
        }
    }

    const deleteCampaign = () => {
        if (user.userId) {
            dispatch(deleteCompanies(id, jwtToken?.jwtToken, sortData));
        }
    }

    const changeStatusBodyTemplate = (rowData: any) => {
        let statusId = null;

        companiesList.map((item: any) => {
            if (item.campaignId === rowData.campaignId) {
                StateCampaign.campaignId = item.campaignId;
            }
            if (item.statusId === rowData.statusId) {
                statusId = item.statusId;
                StateCampaign.statusId = item.statusId;
            }
        });

        const updateCampaignStatus = (campaignId: number, notificationType: number) => {
            switch (notificationType) {
                case 1:
                    if (!cancelNotification && user.userId) {
                        dispatch(getNotification(user.userId, 107, jwtToken?.jwtToken));
                    }
                    setCancelNotificationStatusChange(true);
                    break;
                case 2:
                    if (!launchNotification && user.userId) {
                        dispatch(getNotification(user.userId, 105, jwtToken?.jwtToken));
                    }
                    setLaunchNotificationStatusChange(true);
                    break;
                case 3:
                    if (!suspendNotification && user.userId) {
                        dispatch(getNotification(user.userId, 106, jwtToken?.jwtToken));
                    }
                    setSuspendNotificationStatusChange(true);
                    break;
                default:
            }
            setCampaignId(campaignId);
        }

        return (
            <React.Fragment>
                <>
                    <div className="item controls-buttons">
                        {hasDialerDeletePermission && (<Button disabled={!archiveTableToggle || statusId === 6} onClick={() => updateCampaignStatus(rowData.campaignId, 1)} icon="pi pi-stop" className="p-button-text my-0 mx-auto p-0"/>)}
                        {statusId !== 2 && statusId !== 3 && hasDialerUpdatePermission ? <Button disabled={!archiveTableToggle || statusId === 6} onClick={() => updateCampaignStatus(rowData.campaignId, 2)} icon="pi pi-play" className="p-button-text my-0 mx-auto p-0"/> : ''}
                        {statusId === 2 || statusId === 3 && hasDialerUpdatePermission ? <Button disabled={!archiveTableToggle || statusId === 6} onClick={() => updateCampaignStatus(rowData.campaignId, 3)} icon="pi pi-pause" className="p-button-text my-0 mx-auto p-0"/> : <></>}
                    </div>
                </>
            </React.Fragment>
        );
    };

    const operatorsBodyTemplate = (rowData: any) => {
        return (
            <div className="pl-2">
                {rowData.activeOperators} / {rowData.countUsers}
            </div>
        )
    }

    const countContactsBodyTemplate = (rowData: any) => {
        return (
            <div className="pl-2">
                {rowData.calledContactCount} / {rowData.countContacts}
            </div>
        )
    }

    const isDoneContactCount = (rowData: any) => {
        return (
            <div className="pl-2">
                {rowData.isDoneContactCount}
            </div>
        )
    }

    function calculatePercentCount(percent: number) {
        return isNaN(percent) ? 0 : Math.round(percent);
    }

    function getProgressBarColor(percent: number) {
        return percent <= 10 ? '#98ceec' :
            percent <= 20 ? '#84c1e7' :
                percent <= 30 ? '#72b5e3' :
                    percent <= 40 ? '#64abdf' :
                        percent <= 50 ? '#529fdb' :
                            percent <= 60 ? '#4295d7' :
                                percent <= 70 ? '#348cd3' :
                                    percent <= 80 ? '#2783d0' :
                                        percent <= 90 ? '#1678cc' :
                                            percent <= 100 ? '#026bc7' : '#026bc7';
    }

    const isDoneContactCountPercent = (rowData: any) => {
        const percent = calculatePercentCount(rowData.calledContactCount * 100 / rowData.countContacts);
        const progressBarColor = getProgressBarColor(percent);

        return (
            <div className="flex justify-content-center align-items-center justify-content-between pl-2 w-full">
                <div>{percent}%</div>
                <ProgressBar color={progressBarColor} className='w-8 mr-2' showValue={false} value={percent}></ProgressBar>

            </div>
        )
    }

    const actionTemplate = (rowData: any) => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        let cancelNotification = getItem(codes.cancel);
        const deletionToastShow = (campaignId: number) => {
            if (!cancelNotification) {
                dispatch(getNotification(user.userId, 107, jwtToken?.jwtToken));
            }
            setVisible(true);
            setId(campaignId);
        }

        const copyToastShow = (campaignId: number) => {
            if (!copyNotification) {
                dispatch(getNotification(user.userId, 108, jwtToken?.jwtToken));
            }
            setShowCopyNotification(true);
            setId(campaignId);
        }
        return (
            <div className="flex">
                <div className="controls-buttons">
                    {hasDialerCreatePermission && (<Button className="p-button-text p-button-icon" onClick={() => copyToastShow(rowData.campaignId)} icon="pi pi-copy"></Button>)}
                </div>
                {archiveTableToggle && hasDialerDeletePermission && <Button className="p-button-text" icon="pi pi-trash" onClick={() => deletionToastShow(rowData.campaignId)}></Button>}
            </div>
        );
    };

    const statusBodyTemplate = (rowData: any) => {
        let statusName = statusesDict.map((item) => {
            if (item.code === rowData.statusId) {
                return item.name
            }
        })
        return <span className={`customer-badge status-${rowData.statusId}`}>{statusName}</span>;
    };

    const nameBodyTemplate = (rowData: any) => {
        let campaignName = '';
        let list = companiesList;
        if (!archiveTableToggle) {
            list = companiesListArchived
        }
        list.map((item: any) => {
            if (item.campaignId === rowData.campaignId) {
                let name = item.name;
                const truncateWithEllipses = (text: string, max: number) => {
                    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
                }

                if (name) {
                    campaignName = truncateWithEllipses(name, 41)
                }
            }
        });
        return <span>{campaignName}</span>;
    };

    const statusFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть статус</div>
                <MultiSelect
                    value={options.value}
                    options={statusesDict.map(item => item.code)}
                    itemTemplate={statusItemTemplate}
                    selectedItemTemplate={selectedStatusTemplate}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const selectedStatusTemplate = (option: any) => {
        let statusName = statusesDict.map((item) => {
            if (item.code === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {statusName},
                </span>
            );
        }

        return "Оберіть статус";
    }

    const statusItemTemplate = (option: any) => {
        let statusName = statusesDict.map((item) => {
            if (item.code === option) {
                return item.name
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {statusName}
                </span>
            </div>
        );
    }

    const typeFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть тип</div>
                <MultiSelect
                    value={options.value}
                    options={companiesDirectoryCampaignType && companiesDirectoryCampaignType.map((item: { dictionaryId: number; }) => item.dictionaryId)}
                    itemTemplate={typeItemTemplate}
                    selectedItemTemplate={selectedTypeTemplate}
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                    onMouseDown={() => dispatch(getUsers(user))}
                />
            </>
        );
    };

    const isGeneralFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть стан</div>
                <MultiSelect
                    value={options.value}
                    options={isGeneral}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const selectedTypeTemplate = (option: any) => {
        let typeName = companiesDirectoryCampaignType.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            );
        }

        return "Оберіть тип";
    }

    const typeItemTemplate = (option: any) => {
        let typeName = companiesDirectoryCampaignType.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            </div>
        );
    }

    const branchBodyTemplate = (rowData: any) => {
        let element = branches?.find((element: { id: number; }) => element?.id === rowData?.branchId);

        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {element?.id} {element?.name}
                </span>
            </React.Fragment>
        );
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""></Button>
    }

    const dataTableRef = useRef<DataTable | any>(null);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
        dispatch(setCurrentPageSize(10));
        dispatch(setCurrentPage(1));
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setCurrentPage(event.page + 1));
        dispatch(setCurrentPageSize(event.rows));
    };

    const formattedBranches = branches?.map((branch: { id: any; name: any; }) => ({
        ...branch,
        label: `${branch.id} ${branch.name}`,
    }));

    const branchFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={formattedBranches}
                    optionLabel="label"
                    optionValue="id"
                    selectedItemsLabel={options?.value?.length ? `Обрано: ${options?.value?.length}` : "0"}
                    maxSelectedLabels={0}
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'campaignId':
                    newOrderBy = 'CampaignId';
                    break;
                case 'campaignTypeId':
                    newOrderBy = 'CampaignTypeId';
                    break;
                case 'isGeneralTrunk':
                    newOrderBy = 'IsGeneralTrunk';
                    break;
                case 'name':
                    newOrderBy = 'Name';
                    break;
                case 'statusId':
                    newOrderBy = 'StatusId';
                    break;
                case 'activeOperators':
                    newOrderBy = 'ActiveOperators';
                    break;
                case 'calledContactCount':
                    newOrderBy = 'CalledContactCount';
                    break;
                case 'isDoneContactCount':
                    newOrderBy = 'IsDoneContactCount';
                    break;
                case 'branchId':
                    newOrderBy = 'BranchId';
                    break;
                case 'percent':
                case 'ProgressCampaign':
                    newOrderBy = 'ProgressCampaign';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };


    const customFilter = (event?: any) => {
        if (event) {
            const {campaignId, name, campaignTypeId, statusId, isGeneralTrunk, branchId} = event.filters;

            setFilterCampaignId(campaignId ? {key: 'CampaignId', value: campaignId.constraints[0].value} : null);
            setFilterCampaignName(name ? {key: 'Name', value: name.constraints[0].value} : null);
            if (campaignTypeId.value) {
                setFilterCampaignTypeId(campaignTypeId.value.map((item: any) => ({key: 'CampaignTypeId', value: String(item)})));
            } else {
                setFilterCampaignTypeId(null);
            }
            if (statusId.value) {
                setFilterStatusId(statusId.value.map((item: any) => ({key: 'StatusId', value: String(item)})));
            } else {
                setFilterStatusId(null);
            }
            if (isGeneralTrunk.value) {
                setFilterIsGeneral(isGeneralTrunk.value.map((item: any) => ({key: 'IsGeneralTrunk', value: String(item)})));
            } else {
                setFilterIsGeneral(null);
            }
            if (branchId.value) {
                setFilterBranchId(branchId.value.map((item: any) => ({key: 'BranchId', value: String(item)})));
            } else {
                setFilterBranchId(null);
            }
        } else {
            setFilterCampaignTypeId(null);
            setFilterCampaignId(null);
            setFilterCampaignName(null);
            setFilterStatusId(null);
            setOrderBy(null);
            setIsDescFilter(true);
            setFilterIsGeneral(null);
            setFilterBranchId(null);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center sticky top-0" onClick={() => customSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const campaignDataSort = {
        pageNumber: campaignContactsPageSize.page,
        pageSize: campaignContactsPageSize.size,
        sort: {
            orderBy: "ContactName",
            "isDesc": false
        },
        campaignId: null
    }

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const openEditCampaignDialog = () => {
        dispatch(setSelectedCompanyIndex(companiesData.campaign?.campaignId || selectedRowData));
        dispatch(setDialogVisible(true));
        dispatch(getCampaignId(selectedRowData, jwtToken?.jwtToken));
        campaignDataSort.campaignId = selectedRowData;
        dispatch(getCampaignContactsWithPagination(campaignDataSort, jwtToken?.jwtToken))
    }

    const dialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    openEditCampaignDialog();
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': archiveTableToggle && hasDialerUpdatePermission,
                        'pi pi-eye': !archiveTableToggle || !hasDialerUpdatePermission
                    })}></span></Button>
            </div>
        </>
    }

    return (
        <div className="layout-dashboard">
            <Toast ref={toast}/>
            <div className="">
                <div className="flex col-12 md:col-12 flex-wrap p-0">
                    {/*<div className="card widget-table">*/}
                    <div className={classNames('card widget-table col-12 transition-duration-300 monitoring-table align-self-start m-0 mr-0', {'': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                        <CompaniesListNav handleReset={handleReset}/>
                        <DataTable
                            ref={dataTableRef}
                            scrollable
                            scrollHeight={(selectedRowData) && !isMobile() ? '40vh' : '75vh'}
                            className="p-datatable-customers"
                            value={archiveTableToggle ? companiesListWithPercent : companiesListArchivedWithPercent}
                            dataKey="id"
                            filters={clearFilters}
                            rowsPerPageOptions={[10, 15, 25, 50]}
                            rowHover={false}
                            selectionMode="single"
                            selection={selectedRowData}
                            onSort={customSort}
                            onFilter={customFilter}
                            emptyMessage="Нічого не знайдено"
                            responsiveLayout='scroll'
                            onRowClick={(e) => {
                                setSelectedRowData(e.data.campaignId);
                                dispatch(getCampaignId(e.data.campaignId, jwtToken?.jwtToken));
                                dispatch(setSelectedCompanyIndex(e.data.campaignId));
                                setMobileVisible(true);
                                if (isMobile()) {
                                    setMobileVisible(true);
                                }
                            }}
                            loading={loader}
                            rowClassName={(rowData: any) =>
                                rowData?.campaignId === selectedRowData ? 'p-highlight' : ''
                            }
                        >
                            <Column
                                field="campaignId"
                                filterField="campaignId"
                                header={renderHeader('ID', 'CampaignId')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть ID кампанії"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'CampaignId'})}
                                body={(data) => data.campaignId}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '6%', minWidth: '90px'}}
                            />
                            <Column
                                field="branchId"
                                filterField="branchId"
                                header={renderHeader('ID / Branch', 'BranchId')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть назву"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'Id'})}
                                body={branchBodyTemplate}
                                filterElement={branchFilterTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '6%', minWidth: '200px'}}
                            />
                            {archiveTableToggle && !MVP && <Column field="priority" header="Пріорітет" sortable body={(data) => data.priority} style={{cursor: 'pointer', width: '6%'}}></Column>}
                            <Column
                                filterField="campaignTypeId"
                                header={renderHeader('Тип', 'CampaignTypeId')}
                                className={classNames('', {'active-header-column': orderBy === 'CampaignTypeId'})}
                                showFilterMatchModes={false}
                                body={typeBodyTemplate}
                                filter
                                sortable
                                field="campaignTypeId"
                                filterElement={typeFilterTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '110px'}}
                            />
                            <Column
                                filterField="isGeneralTrunk"
                                header={renderHeader('А номер', 'IsGeneralTrunk')}
                                className={classNames('', {'active-header-column': orderBy === 'IsGeneralTrunk'})}
                                showFilterMatchModes={false}
                                body={isGeneralBodyTemplate}
                                filter
                                sortable
                                field="isGeneralTrunk"
                                filterElement={isGeneralFilterTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '15%', minWidth: '150px'}}
                            />
                            <Column
                                field="name"
                                filterField="name"
                                header={renderHeader('Назва', 'Name')}
                                className={classNames('', {'active-header-column': orderBy === 'Name'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть назву кампанії"
                                filter
                                sortable
                                body={nameBodyTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '20%', minWidth: '150px'}}
                            />
                            <Column
                                filterField="statusId"
                                header={renderHeader('Статус', 'StatusId')}
                                className={classNames('', {'active-header-column': orderBy === 'StatusId'})}
                                showFilterMatchModes={false}
                                body={statusBodyTemplate}
                                sortable
                                field="statusId"
                                filter
                                filterElement={statusFilterTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '8%', minWidth: '120px'}}
                            />
                            {archiveTableToggle && (hasDialerUpdatePermission || hasDialerDeletePermission) && <Column field="statusId" header="Управління кампанією" style={{cursor: 'pointer', width: '7%', minWidth: '90px'}} body={changeStatusBodyTemplate}></Column>}
                            <Column
                                field="activeOperators"
                                header={renderHeader('Оператори', 'ActiveOperators')}
                                className={classNames('', {'active-header-column': orderBy === 'ActiveOperators'})}
                                sortable body={operatorsBodyTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '115px'}}
                            />
                            <Column
                                field="calledContactCount"
                                header={renderHeader('Опрацьовано контактів', 'CalledContactCount')}
                                className={classNames('', {'active-header-column': orderBy === 'CalledContactCount'})}
                                sortable
                                body={countContactsBodyTemplate}
                                style={{cursor: 'pointer', width: archiveTableToggle && (hasDialerUpdatePermission || hasDialerDeletePermission) ? '15%' : '22%', minWidth: '130px'}}/>
                            <Column
                                field="percent"
                                header={renderHeader('Опрацьовано %', 'ProgressCampaign')}
                                className={classNames('', {'active-header-column': orderBy === 'ProgressCampaign'})}
                                sortable sortField="percent"
                                body={isDoneContactCountPercent}
                                style={{cursor: 'pointer', width: '15%', minWidth: '150px'}}/>
                            <Column
                                field="isDoneContactCount"
                                header={renderHeader('Успішно', 'IsDoneContactCount')}
                                className={classNames('', {'active-header-column': orderBy === 'IsDoneContactCount'})}
                                sortable
                                body={isDoneContactCount}
                                style={{
                                    cursor: 'pointer',
                                    width: (hasDialerCreatePermission || hasDialerDeletePermission) && archiveTableToggle ? '6%' : '11%',
                                    minWidth: '95px'
                                }}/>
                            {(hasDialerCreatePermission || hasDialerDeletePermission) && (<Column header="Дія" style={{cursor: 'pointer', width: '5%', minWidth: '100px'}} body={actionTemplate}></Column>)}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={pagination.totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                        />
                    </div>
                    {selectedRowData && companies && (
                        <>
                            {windowWidth > 1248 && <div className={classNames('card widget-table col-12 md:col-12 align-self-start sticky  mt-4 top-0')}>
                                <CompaniesListDetails setSelectedRowData={setSelectedRowData} selectedRowData={selectedRowData} windowWidth={windowWidth}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={mobileVisible} header={dialogHeader} className="monitoring-info" onHide={() => setMobileVisible(false)}>
                                    <CompaniesListDetails/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                    <CompaniesListDialog/>

                    <ConfirmDialog draggable={false} visible={cancelNotificationStatusChange} onHide={() => setCancelNotificationStatusChange(false)} message={cancelMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => cancelCampaign()}/>
                    <ConfirmDialog visible={launchNotificationStatusChange} onHide={() => setLaunchNotificationStatusChange(false)} message={launchMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => launchCampaign()}/>
                    <ConfirmDialog visible={suspendNotificationStatusChange} onHide={() => setSuspendNotificationStatusChange(false)} message={suspendMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => suspendCampaign()}/>
                    <ConfirmDialog visible={showCopyNotification} onHide={() => setShowCopyNotification(false)} message={copyCampaignMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => copyCampaignMethod()}/>
                    <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={deleteCampaignMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteCampaign()}/>
                </div>
            </div>
        </div>
    );
};

export default CompaniesList;
