import {
    ADD_AUDIO_BRANCH,
    ARCHIVE_AUDIO_TABLE_TOGGLE,
    AUDIO_CLEAR_FILTERS,
    AUDIO_DIALOG_VISIBLE,
    AUDIO_FIELD, DELETE_AUDIO_BRANCH,
    INITIAL_AUDIO_DATA,
    SELECTED_AUDIO_ID,
    SET_AUDIO_CURRENT_PAGE,
    SET_AUDIO_CURRENT_SIZE,
    SET_AUDIO_ERROR_FIELDS,
    SHOW_AUDIO_LOADER
} from "../types/typesAudioList";


export function setArchiveAudioTableToggle(state: boolean) {
    return {
        type: ARCHIVE_AUDIO_TABLE_TOGGLE,
        payload: state,
    }
}

export function setAudioDialogVisible(state: boolean) {
    return {
        type: AUDIO_DIALOG_VISIBLE,
        payload: state,
    }
}

export function setSelectedAudioIndex(state: number) {
    return {
        type: SELECTED_AUDIO_ID,
        payload: state,
    }
}

export function setAudioField(state: { section: string, fieldName: string, value: any }) {
    return {
        type: AUDIO_FIELD,
        payload: state,
    }
}

export function setAudioClearFilters() {
    return {
        type: AUDIO_CLEAR_FILTERS,
    }
}

export function showAudioLoader(state: boolean) {
    return {
        type: SHOW_AUDIO_LOADER,
        payload: state,
    }
}

export function initialAudioData() {
    return {
        type: INITIAL_AUDIO_DATA,
    }
}

export function setAudioErrorField(state: any) {
    return {
        type: SET_AUDIO_ERROR_FIELDS,
        payload: state,
    }
}


export function setAudioCurrentPage(state: any) {
    return {
        type: SET_AUDIO_CURRENT_PAGE,
        payload: state
    }
}

export function setAudioCurrentPageSize(state: any) {
    return {
        type: SET_AUDIO_CURRENT_SIZE,
        payload: state
    }
}

export function addAudioBranch(state: {audioId:any, id:any}) {
    return {
        type: ADD_AUDIO_BRANCH,
        payload: state,
    }
}

export function deleteAudioBranch(state: string) {
    return {
        type: DELETE_AUDIO_BRANCH,
        payload: state,
    }
}
