import  {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Toast} from "primereact/toast";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {getItem} from "../../../redux/cache/cacheService";
import {codes} from "../../../redux/notificationCodes";
import {ProgressBar} from "primereact/progressbar";
import {getNotification} from "../../../redux/api/api";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Paginator} from "primereact/paginator";
import {setEditType} from "../../../redux/actions/actions";
import React from 'react';
import {
    setCurrentQualificationGroupPage,
    setCurrentQualificationGroupPageSize,
    setQualificationGroupArchiveTableToggle,
    setQualificationGroupDialogVisible,
    setQualificationGroupErrorFields,
    setQualificationGroupListDataSort,
    setSelectedQualificationGroupIndex
} from "../../../redux/actions/actionsQualificationGroup";
import {archiveQualificationGroup, copyQualificationGroup, getQualificationGroupDetails, getQualificationGroupListWithPaginationFilterAndSorting} from "../../../redux/api/apiQualificationGroup";
import QualificationGroupListNav from "../QualificationGroupListNav";
import QualificationGroupDialog from "../QualificationGroupDialog";
import QualificationGroupListDetails from "../QualificationGroupListDetails";
import BranchDialog from "../../Branch/BranchDialog";

const QualificationGroupList = () => {
    const dispatch = useAppDispatch();
    const companies = useAppSelector(state => state.CompaniesListReducer);
    const clearFilters = useAppSelector(state => state.QualificationGroupListReducer.clearFilters);
    const pagination = useAppSelector(state => state.QualificationGroupListReducer.pagination);
    const paginationPage = useAppSelector(state => state.QualificationGroupListReducer.paginationPage);
    const paginationSize = useAppSelector(state => state.QualificationGroupListReducer.paginationSize);
    const archiveQualificationGroupTableToggle = useAppSelector(state => state.QualificationGroupListReducer.archiveQualificationGroupTableToggle);
    const loader = useAppSelector(state => state.QualificationGroupListReducer.loading);
    const [unzipNotifyVisible, setUnzipNotifyVisible] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [mobileVisible, setMobileVisible] = useState<boolean>(false);
    const [showCopyNotification, setShowCopyNotification] = useState<boolean>(false);
    const [id, setId] = useState<number>(0);
    const toast = useRef<Toast>(null);
    const [first, setFirst] = useState(0);
    const [data] = useState<any>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [filterStatusesId, setFilterStatusesId] = useState<any>(null);
    const [filterTypeId, setFilterTypeId] = useState<any>(null);
    const [filterStatusName, setFilterStatusName] = useState<any>(null);
    const [filterStatusId] = useState<any>(null)
    const [sortOrder, setSortOrder] = useState<number>(0);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const rolePermissions = checkPermissions(rolesVision, 'SkillGroupManager');
    const [page, setPage] = useState<any>(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const qualificationGroupList = useAppSelector(state => state.QualificationGroupListReducer.qualificationGroupList) as unknown as any;
    const notifications = useAppSelector(state => state.NotificationsReducer);
    const qualificationData = useAppSelector(state => state.QualificationGroupListReducer.qualificationData) as unknown as any;
    let inSystem = qualificationData?.inSystem;
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const [sortData, setSortData] = useState<any>({
        isArchive: !archiveQualificationGroupTableToggle,
        pageNumber: page ? page : paginationPage,
        pageSize: paginationSize,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        }
    });
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);

    const isActive = [
        {name: 'Активна', value: "true"},
        {name: 'Не активна', value: "false"},
    ];

    const {
        create: hasCreatePermission,
        update: hasUpdatePermission,
        delete: hasDeletePermission,
    } = rolePermissions;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(setCurrentQualificationGroupPage(1));
    }, []);

    useEffect(() => {
        dispatch(setCurrentQualificationGroupPageSize(10));
        dispatch(setQualificationGroupArchiveTableToggle(true));
    }, []);

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0; // Сброс прокрутки
            scrollableBody.scrollLeft = 0;
        }
    };

    useEffect(() => {
        setSelectedRowData(null);
        let filter: { key: string; value: string }[] = [];
        const dataSort: any = {
            isArchive: !archiveQualificationGroupTableToggle,
            pageNumber: page ? page : paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            }
        }

        if (filterStatusesId?.value) {
            filter.push(filterStatusesId);
        }
        if (filterStatusName?.value) {
            filter.push(filterStatusName);
        }
        if (filterTypeId?.value) {
            filter.push(filterTypeId);
        }
        if (filter) {
            dataSort['filter'] = filter;
        }
        dispatch(getQualificationGroupListWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
        dispatch(setQualificationGroupListDataSort(dataSort));
        setPage(null);
        setSortData(dataSort);
        resetTableHorizontalScroll();
    }, [paginationPage, paginationSize, data, archiveQualificationGroupTableToggle, orderBy, isDescFilter, filterStatusesId, filterStatusName, filterStatusId, filterTypeId]);

    useEffect(() => {
        if (activeClick?.item === "/skill_group" && activeClick?.from ==='#/skill_group') {
            dispatch(getQualificationGroupListWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    let cancelNotification = getItem(codes.archive_qualification_group_question);
    let copyNotification = getItem(codes.copy_qualification_group_question);
    let unzipNotification = getItem(codes.un_archive_qualification_group_question);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    // @ts-ignore
    const copyMessage = <div>{companies && companies.notificationCopy ? companies.notificationCopy.text : copyNotification ? copyNotification : progressBar}</div>
    const deleteMessage = <div>{notifications && notifications.archiveQualificationGroupMessage ? notifications.archiveQualificationGroupMessage.text : cancelNotification ? cancelNotification : progressBar}</div>



    useEffect(() => {
        setFirst(0);
        dispatch(setCurrentQualificationGroupPageSize(10));
        dispatch(setCurrentQualificationGroupPage(1));
    }, [archiveQualificationGroupTableToggle]);


    const copyCampaignMethod = () => {
        if (jwtToken?.jwtToken) {
            dispatch(copyQualificationGroup(id, jwtToken?.jwtToken, sortData));
        }
    }

    const deleteQualificationGroup = () => {
        if (jwtToken?.jwtToken) {
            const archiveStatusData = {
                skillGroupId: id,
                isArchive: true
            };
            dispatch(archiveQualificationGroup(archiveStatusData, jwtToken?.jwtToken, sortData));
        }
    };

    const unzipQualificationGroup = () => {
        if (jwtToken?.jwtToken) {
            const archiveStatusData = {
                skillGroupId: id,
                isArchive: false
            };
            dispatch(archiveQualificationGroup(archiveStatusData, jwtToken?.jwtToken));
        }
    };

    const actionTemplate = (rowData: any) => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        let cancelNotification = getItem(codes.cancel);
        const deletionToastShow = (campaignId: number) => {
            if (!cancelNotification) {
                dispatch(getNotification(user.userId, 190, jwtToken?.jwtToken));
            }
            setVisible(true);
            setId(campaignId);
        }

        const copyToastShow = (campaignId: number) => {
            if (!copyNotification) {
                dispatch(getNotification(user.userId, 193, jwtToken?.jwtToken));
            }
            setShowCopyNotification(true);
            setId(campaignId);
        }

        const unzipToastShow = (campaignId: number) => {
            if (!unzipNotification) {
                dispatch(getNotification(user.userId, 107, jwtToken?.jwtToken));
            }
            setUnzipNotifyVisible(true);
            setId(campaignId);
        }
        return (
            <div className="flex">
                <div className="controls-buttons">
                    {hasCreatePermission && (<Button className="p-button-text p-button-icon" onClick={() => copyToastShow(rowData.id)} icon="pi pi-copy"></Button>)}
                </div>
                {archiveQualificationGroupTableToggle && hasDeletePermission && <Button className="p-button-text" disabled={rowData.inSystem} icon="pi pi-trash" onClick={() => deletionToastShow(rowData.id)}></Button>}
                {!archiveQualificationGroupTableToggle && hasDeletePermission && <Button className="p-button-text" icon="pi pi-folder-open" onClick={() => unzipToastShow(rowData.id)}></Button>}
            </div>
        );
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""></Button>
    }

    const dataTableRef = useRef<any>(null);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
        dispatch(setCurrentQualificationGroupPageSize(10));
        dispatch(setCurrentQualificationGroupPage(1));
        setFirst(0);
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setCurrentQualificationGroupPage(event.page + 1));
        dispatch(setCurrentQualificationGroupPageSize(event.rows));
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'id':
                    newOrderBy = 'Id';
                    break;
                case 'name':
                    newOrderBy = 'Name';
                    break;
                case 'isActive':
                    newOrderBy = 'IsActive';
                    break;
                case 'operatorsCount':
                    newOrderBy = 'OperatorsCount';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };


    const customFilter = (event?: any) => {
        if (event) {
            const {id, name, isActive} = event.filters;
            setFilterStatusesId(id ? {key: 'Id', value: id.constraints[0].value} : null);
            setFilterStatusName(name ? {key: 'Name', value: name.constraints[0].value} : null);
            setFilterTypeId(name ? {key: 'IsActive', value: isActive?.value} : null);
        } else {
            setFilterStatusesId(null);
            setFilterTypeId(null);
            setFilterStatusName(null);
            setOrderBy(null);
            setIsDescFilter(true);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center sticky top-0" onClick={() => customSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const openEditCampaignDialog = () => {
        dispatch(setSelectedQualificationGroupIndex(qualificationData?.id || selectedRowData));
        dispatch(setQualificationGroupDialogVisible(true));
        setSelectedRowData(null);
        if (hasUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const dialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    openEditCampaignDialog();
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': archiveQualificationGroupTableToggle && hasUpdatePermission && !inSystem,
                        'pi pi-eye': !archiveQualificationGroupTableToggle || !hasUpdatePermission || inSystem
                    })}></span></Button>
            </div>
        </>
    }

    const activeFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Виберіть:</div>
                <Dropdown
                    value={options.value}
                    options={isActive}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const operatorsBodyTemplate = (rowData: any) => {
        return (
            <div className="pl-2">
                {rowData.operatorsCount || 0}
            </div>
        )
    }

    return (
        <div className="layout-dashboard">
            <Toast ref={toast}/>
            <div className="">
                <div className="flex col-12 md:col-12 flex-wrap p-0">
                    <div className={classNames('card widget-table col-12 transition-duration-300 monitoring-table align-self-start m-0 mr-0', {'': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                        <QualificationGroupListNav handleReset={handleReset} setSelectedRowData={setSelectedRowData}/>
                        <DataTable
                            ref={dataTableRef}
                            scrollable
                            scrollHeight={(selectedRowData) && !isMobile() ? '40vh' : '70vh'}
                            className="p-datatable-customers"
                            value={qualificationGroupList}
                            dataKey="id"
                            filters={clearFilters}
                            rowHover={false}
                            selectionMode="single"
                            selection={selectedRowData}
                            onSort={customSort}
                            onFilter={customFilter}
                            emptyMessage="Нічого не знайдено"
                            responsiveLayout='scroll'
                            onRowClick={(e) => {
                                setSelectedRowData(e.data.id);
                                dispatch(getQualificationGroupDetails(e.data.id, jwtToken?.jwtToken));
                                dispatch(setSelectedQualificationGroupIndex(e.data.id));
                                dispatch(setQualificationGroupErrorFields(null));
                                setMobileVisible(true);
                                if (isMobile()) {
                                    setMobileVisible(true);
                                }
                            }}
                            loading={loader}
                            rowClassName={(rowData: any) =>
                                rowData?.id === selectedRowData ? 'p-highlight' : ''
                            }
                        >
                            <Column
                                field="id"
                                filterField="id"
                                header={renderHeader('ID', 'Id')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть ID:"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'CampaignId'})}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '90px'}}
                            />
                            <Column
                                field="name"
                                filterField="name"
                                header={renderHeader('Назва', 'Name')}
                                className={classNames('', {'active-header-column': orderBy === 'Name'})}
                                filterPlaceholder="Введіть назву групи"
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer',   width: '40%', minWidth: '150px'}}
                            />
                            <Column
                                field="isActive"
                                filterField="isActive"
                                header={renderHeader('Стан', 'Active')}
                                className={classNames('', {'active-header-column': orderBy === 'Active'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={data => data?.isActive ? 'Активна' : 'Не активна'}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={activeFilterTemplate}
                                style={{cursor: 'pointer', width: '20%', minWidth: '150px'}}
                            />
                            <Column
                                field="operatorsCount"
                                header={renderHeader('Оператори', 'OperatorsCount')}
                                className={classNames('', {'active-header-column': orderBy === 'OperatorsCount'})}
                                sortable body={operatorsBodyTemplate}
                                style={{cursor: 'pointer', width: '30%', minWidth: '115px'}}
                            />
                            {(hasCreatePermission || hasDeletePermission) && (<Column header="Дія" style={{cursor: 'pointer', width: '10%', minWidth: '100px'}} body={actionTemplate}></Column>)}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={pagination.totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                        />
                    </div>
                    {selectedRowData && companies && (
                        <>
                            {windowWidth > 1248 && <div className={classNames('card widget-table col-12 md:col-12 align-self-start mt-4 sticky top-0')}>
                                <QualificationGroupListDetails setSelectedRowData={setSelectedRowData} selectedRowData={selectedRowData} windowWidth={windowWidth}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={mobileVisible} header={dialogHeader} className="monitoring-info" onHide={() => setMobileVisible(false)}>
                                    <QualificationGroupListDetails/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                    <QualificationGroupDialog/>
                    <ConfirmDialog visible={showCopyNotification} onHide={() => setShowCopyNotification(false)} message={copyMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => copyCampaignMethod()}/>
                    <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message={deleteMessage}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => deleteQualificationGroup()}/>
                    <ConfirmDialog visible={unzipNotifyVisible} onHide={() => setUnzipNotifyVisible(false)} message={'Групу кваліфікацій буде розархівовано!  Розахівувати?'}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => unzipQualificationGroup()}/>
                </div>
            </div>
            <BranchDialog/>
        </div>
    );
};

export default QualificationGroupList;
