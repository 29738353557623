import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationMenu from "../index";

const Topbar = (props: any) => {

    const navigate = useNavigate();

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="logo p-link relative ml-2" onClick={() => navigate('/')}>
                        <img src={`/assets/layout/images/trr.svg`} alt="logo" />
                        <p className="app-name text-logo text-phone text-phone-topbar">phone</p>
                    </button>
                </div>

                <NavigationMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                    onMenuButtonClick={props.onMenuButtonClick}
                />
            </div>
        </>
    );
};

export default Topbar;
