import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {MultiSelect} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {setEditType} from "redux/actions/actions";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Paginator} from "primereact/paginator";
import {classNames} from "primereact/utils";
import {Dialog} from "primereact/dialog";
import {ProgressBar} from "primereact/progressbar";
import {checkPermissions} from "../../../../redux/permissions/permissionsUtils";
import TrunkListNav from "../AudioListNav";
import {setTrunkIds} from "../../../../redux/actions/actionsTrunk";
import {getItem} from "../../../../redux/cache/cacheService";
import {codes} from "../../../../redux/notificationCodes";
import {getUsersManagerUserBranchesData} from "../../../../redux/api/apiUsersList";
import AudioDialog from "../AudioDialog";
import AudioListDetails from "./AudioListDetails";
import {archiveAudio, getAllAudioWithPagination, getAudioById} from "../../../../redux/api/apiAudio";
import {setArchiveAudioTableToggle, setAudioCurrentPage, setAudioCurrentPageSize, setAudioDialogVisible, setAudioErrorField, setSelectedAudioIndex} from "../../../../redux/actions/actionsAudio";
import {getNotification} from "../../../../redux/api/api";
import BranchDialog from "../../../Branch/BranchDialog";

const AudioList = () => {
    const dispatch = useAppDispatch();
    const clearFilters = useAppSelector(state => state.AudioListReducer.clearFilters);
    const audiosList = useAppSelector(state => state.AudioListReducer.audiosList);
    // @ts-ignore
    const pagination = useAppSelector(state => state.AudioListReducer.pagination);
    // @ts-ignore
    const paginationPage = useAppSelector(state => state.AudioListReducer.paginationPage);
    // @ts-ignore
    const paginationSize = useAppSelector(state => state.AudioListReducer.paginationSize);
    const user = useAppSelector(state => state.UsersReducer.userData);
    const archiveTableToggle = useAppSelector(state => state.AudioListReducer.archiveAudioTableToggle);
    // @ts-ignore
    const loader = useAppSelector(state => state.AudioListReducer.loading);
    const [mobileVisible, setMobileVisible] = useState<boolean>(false);
    const toast = useRef<Toast>(null);
    const [first, setFirst] = useState(0);
    const [data] = useState<any>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [filterAudioId, setFilterAudioId] = useState<any>(null);
    const [filterAudioName, setFilterAudioName] = useState<any>(null);
    const [filterActive, setFilterActive] = useState<any>(null);
    const [filterHold, setFilterHold] = useState<any>(null);
    const [filterRingback, setFilterRingback] = useState<any>(null);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Trunks');
    const [page, setPage] = useState<any>(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const unzipAudioMessage = useAppSelector(state => state.NotificationsReducer.unzipAudioMessage);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [visibleDeletedMessage, setVisibleDeletedMessage] = useState<any>(false);
    const [visibleUnzipMessage, setVisibleUnzipMessage] = useState<any>(false);
    const [deletedUserInfo, setDeletedUserInfo] = useState<any>(false);
    const audioDeleteMessage = useAppSelector(state => state.NotificationsReducer.audioDeleteMessage);
    const [sortData, setSortData] = useState<any>({
        isArchived: !archiveTableToggle,
        pageNumber: page ? page : paginationPage,
        pageSize: paginationSize,
        sort: {
            orderBy: orderBy ? orderBy : "AudioId",
            isDesc: isDescFilter
        }
    });
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const {
        create: hasDialerCreatePermission,
        update: hasDialerUpdatePermission,
        delete: hasDialerDeletePermission,
    } = dialerPermissions;

    const isActive = [
        {name: 'Активна', value: true},
        {name: 'Не активна', value: false},
    ];

    const isHold = [
        {name: 'Hold', value: "true"},
    ];

    const isRingback = [
        {name: 'Ringback', value: "true"},
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(setAudioCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(getUsersManagerUserBranchesData(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        dispatch(setAudioCurrentPageSize(10));
        dispatch(setArchiveAudioTableToggle(true));
    }, []);

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0;
            scrollableBody.scrollLeft = 0;
        }
    };

    useEffect(() => {
        let filter: { key: string; value: string }[] = [];
        const dataSort: any = {
            pageNumber: page ? page : paginationPage,
            pageSize: paginationSize,
            isArchived: !archiveTableToggle,
            sort: {
                orderBy: orderBy ? orderBy : "AudioId",
                isDesc: isDescFilter
            }
        }
        if (filterAudioId?.value) {
            filter.push(filterAudioId);
        }
        if (filterAudioName?.value) {
            filter.push(filterAudioName);
        }

        if (filter) {
            dataSort['filter'] = filter;
        }
        if (filterActive && filterActive.length) {
            if (filterActive.length !== 2) {
                filterActive.forEach((item: any) => {
                    dataSort['isActive'] = item.value;
                    if (item.value === "true") {
                        dataSort['isActive'] = true;
                    } else if (item.value === "false") {
                        dataSort['isActive'] = false;
                    }
                });
            }
        } else {
            if ("isActive" in dataSort) {
                delete dataSort.isActive;
            }
        }

        if (filterRingback && filterRingback.length) {
            filter.push(filterRingback[0]);
        }

        if (filterHold && filterHold.length) {
            filter.push(filterHold[0]);
        }
        dispatch(getAllAudioWithPagination(dataSort, jwtToken?.jwtToken));
        setSortData(dataSort);
        setPage(null);
        resetTableHorizontalScroll();
    }, [paginationPage, paginationSize, data, archiveTableToggle, orderBy, filterActive, isDescFilter, filterAudioId, filterAudioName, filterRingback, filterHold]);

    useEffect(() => {
        if (activeClick?.item === "/audio" && activeClick?.from === "#/audio") {
            dispatch(getAllAudioWithPagination(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    let audioDeletedMessage = getItem(codes.audio_delete_questions);
    let unzipNotification = getItem(codes.un_archive_audio_question);

    const progressBar = <ProgressBar mode="indeterminate" style={{height: '2px', maxWidth: '95%'}}></ProgressBar>;
    let audioDeleteQuestions = <div>{audioDeleteMessage ? audioDeleteMessage.text : audioDeletedMessage ? audioDeletedMessage : progressBar}</div>
    let unArchiveQueueQuestion = <div>{unzipAudioMessage && unzipAudioMessage?.text ? unzipAudioMessage?.text : unzipNotification ? unzipNotification : progressBar}</div>

    useEffect(() => {
        if (unzipAudioMessage?.text) {
            unArchiveQueueQuestion = <div>{unzipAudioMessage && unzipAudioMessage?.text ? unzipAudioMessage?.text : unzipNotification ? unzipNotification : progressBar}</div>
        }
    }, [unzipAudioMessage])

    useEffect(() => {
        if (audioDeleteMessage?.text) {
            audioDeleteQuestions = <div>{audioDeleteMessage ? audioDeleteMessage.text : audioDeletedMessage ? audioDeletedMessage : progressBar}</div>
        }
    }, [audioDeleteMessage])

    useEffect(() => {
        setSelectedRowData(null);
    }, [archiveTableToggle, paginationPage, paginationSize]);

    useEffect(() => {
        setFirst(0);
        dispatch(setAudioCurrentPageSize(10));
        dispatch(setAudioCurrentPage(1));
    }, [archiveTableToggle]);

    const actionTemplate = (rowData: any) => {
        const deletionToastShow = (isActive: boolean) => {
            if (!isActive) {
                setVisibleDeletedMessage(true);
            } else {
                setVisibleUnzipMessage(true)
            }
            if (!audioDeletedMessage && user.userId && !isActive) {
                dispatch(getNotification(user.userId, 219, user));
            }
            if (!unzipAudioMessage && user.userId && !isActive) {
                dispatch(getNotification(user.userId, 220, user));
            }
            let userInfo = {
                audioId: rowData.audioId
            }

            setDeletedUserInfo(userInfo);
        }

        return (
            <div className="flex">
                {archiveTableToggle ?
                    <Button className="p-button-text" icon="pi pi-trash" onClick={() => deletionToastShow(false)}></Button>
                    :
                    <Button className="p-button-text" icon="pi pi-folder-open" onClick={() => deletionToastShow(true)}></Button>
                }
            </div>
        );
    };

    const nameBodyTemplate = (rowData: any) => {
        let campaignName = '';
        audiosList.map((item: any) => {
            if (item.audioId === rowData.audioId) {
                let name = item.audioName;
                const truncateWithEllipses = (text: string, max: number) => {
                    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
                }

                if (name) {
                    campaignName = truncateWithEllipses(name, 41)
                }
            }
        });
        return <span>{campaignName}</span>;
    };

    const isActiveFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={isActive}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const isRingbackFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={isRingback}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const isHoldFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть</div>
                <MultiSelect
                    value={options.value}
                    options={isHold}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""></Button>
    }

    const dataTableRef = useRef<DataTable | any>(null);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
        dispatch(setAudioCurrentPageSize(10));
        dispatch(setAudioCurrentPage(1));
        setFirst(0);
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setAudioCurrentPage(event.page + 1));
        dispatch(setAudioCurrentPageSize(event.rows));
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'AudioId':
                    newOrderBy = 'AudioId';
                    break;
                case 'AudioName':
                    newOrderBy = 'AudioName';
                    break;
                case 'isActive':
                    newOrderBy = 'IsActive';
                    break;
                case 'IsRingback':
                    newOrderBy = 'IsRingback';
                    break;
                case 'IsHold':
                    newOrderBy = 'IsHold';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };


    const customFilter = (event?: any) => {
        if (event) {
            const {audioId, audioName, isActive, isHold, isRingback} = event.filters;

            setFilterAudioId(audioId ? {key: 'AudioId', value: audioId.constraints[0].value} : null);
            setFilterAudioName(audioName ? {key: 'AudioName', value: audioName.constraints[0].value} : null);
            if (isActive.value) {
                setFilterActive(isActive.value.map((item: any) => ({key: 'IsActive', value: item})));
            } else {
                setFilterActive(null);
            }
            if (isHold.value) {
                setFilterHold(isHold.value.map((item: any) => ({key: 'IsHold', value: item})));
            } else {
                setFilterHold(null);
            }
            if (isRingback.value) {
                setFilterRingback(isRingback.value.map((item: any) => ({key: 'IsRingback', value: item})));
            } else {
                setFilterRingback(null);
            }
        } else {
            setFilterAudioId(null);
            setFilterAudioName(null);
            setOrderBy(null);
            setFilterActive(null);
            setIsDescFilter(true);
            setFilterHold(null);
            setFilterRingback(null);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center sticky top-0" onClick={() => customSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const openEditCampaignDialog = () => {
        dispatch(setAudioDialogVisible(true));
        dispatch(getAudioById(selectedRowData, jwtToken?.jwtToken));
        dispatch(setAudioErrorField(null));
        dispatch(setTrunkIds(null));
        setSelectedRowData(null);
        if (hasDialerUpdatePermission) {
            dispatch(setEditType(true));
        } else {
            dispatch(setEditType(false));
        }
    }

    const dialogHeader = () => {
        return <>
            <div className="flex align-items-center justify-content-end m-0 p-0 mr-1">
                <Button className="p-dialog-header-icon p-dialog-header-close p-link border-noround	outline-none shadow-none h-full justify-content-end" onClick={() => {
                    openEditCampaignDialog();
                }}>
                    <span className={classNames('', {
                        'pi pi-pencil': archiveTableToggle && hasDialerUpdatePermission,
                        'pi pi-eye': !archiveTableToggle || !hasDialerUpdatePermission
                    })}></span></Button>
            </div>
        </>
    }

    return (
        <div className="layout-dashboard">
            <Toast ref={toast}/>
            <div className="">
                <div className="flex col-12 md:col-12 p-0 flex-wrap">
                    <div className={classNames('card widget-table col-12 transition-duration-300 monitoring-table align-self-start m-0 mr-0', {'mb-4': selectedRowData && !isMobile(), 'md:col-12': !selectedRowData})}>
                        <TrunkListNav handleReset={handleReset} setSelectedRowData={setSelectedRowData}/>
                        <DataTable
                            ref={dataTableRef}
                            scrollable
                            scrollHeight={(selectedRowData) && !isMobile() ? '35vh' : '70vh'}
                            className="p-datatable-customers monitoring-table"
                            value={audiosList}
                            dataKey="audioId"
                            filters={clearFilters}
                            rowsPerPageOptions={[10, 15, 25, 50]}
                            rowHover={false}
                            selectionMode="single"
                            selection={selectedRowData}
                            onSort={customSort}
                            onFilter={customFilter}
                            emptyMessage="Нічого не знайдено"
                            responsiveLayout='scroll'
                            onRowClick={(e) => {
                                setSelectedRowData(e.data.audioId);
                                dispatch(getAudioById(e.data.audioId, jwtToken?.jwtToken));
                                dispatch(setSelectedAudioIndex(e.data.audioId));
                                setMobileVisible(true);
                                if (isMobile()) {
                                    setMobileVisible(true);
                                }
                            }}
                            loading={loader}
                            rowClassName={(rowData:any) =>
                                rowData?.audioId === selectedRowData ? 'p-highlight' : ''
                            }
                        >
                            <Column
                                field="audioId"
                                filterField="audioId"
                                header={renderHeader('ID', 'AudioId')}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть ID"
                                filter
                                sortable
                                className={classNames('', {'active-header-column': orderBy === 'AudioId'})}
                                body={(data) => data.audioId}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '10%', minWidth: '90px'}}
                            />
                            <Column
                                field="audioName"
                                filterField="audioName"
                                header={renderHeader('Назва', 'AudioName')}
                                className={classNames('', {'active-header-column': orderBy === 'AudioName'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filterPlaceholder="Введіть назву черги"
                                filter
                                sortable
                                body={nameBodyTemplate}
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                style={{cursor: 'pointer', width: '100%', minWidth: '150px'}}
                            />
                            <Column
                                field="isActive"
                                filterField="isActive"
                                header={renderHeader('Стан', 'IsActive')}
                                className={classNames('', {'active-header-column': orderBy === 'IsActive'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={data => data?.isActive ? 'Активна' : 'Не активна'}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={isActiveFilterTemplate}
                                style={{cursor: 'pointer', width: '100%', minWidth: '150px'}}
                            />
                            <Column
                                field="isRingback"
                                filterField="isRingback"
                                header={renderHeader('Ringback', 'IsRingback')}
                                className={classNames('', {'active-header-column': orderBy === 'IsRingback'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={data => data?.isRingback ? 'Ringback' : ''}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={isRingbackFilterTemplate}
                                style={{cursor: 'pointer', width: '100%', minWidth: '150px'}}
                            />
                            <Column
                                field="isHold"
                                filterField="isHold"
                                header={renderHeader('Hold', 'IsHold')}
                                className={classNames('', {'active-header-column': orderBy === 'IsHold'})}
                                showFilterMatchModes={false}
                                showFilterMenuOptions={false}
                                filter
                                body={data => data?.isHold ? 'Hold' : ''}
                                sortable
                                filterClear={filterClearTemplate}
                                filterApply={filterApplyTemplate}
                                filterElement={isHoldFilterTemplate}
                                style={{cursor: 'pointer', width: '100%', minWidth: '150px'}}
                            />
                            {(hasDialerCreatePermission || hasDialerDeletePermission) && (<Column header="Дія" style={{cursor: 'pointer', width: '10%', minWidth: '100px'}} body={actionTemplate}></Column>)}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={pagination.totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                        />
                    </div>
                    {selectedRowData && (
                        <>
                            {windowWidth > 1248 && <div className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                <AudioListDetails setSelectedRowData={setSelectedRowData} selectedRowData={selectedRowData} windowWidth={windowWidth}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={mobileVisible} header={dialogHeader} className="monitoring-info" onHide={() => setMobileVisible(false)}>
                                    <AudioListDetails/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                    <AudioDialog setSelectedRowData={setSelectedRowData}/>
                    <ConfirmDialog visible={visibleDeletedMessage} onHide={() => setVisibleDeletedMessage(false)} message={audioDeleteQuestions}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                        dispatch(archiveAudio(deletedUserInfo?.audioId, jwtToken?.jwtToken, sortData));
                        if (deletedUserInfo.audioId === selectedRowData?.audioId) {
                            setSelectedRowData(null);
                        }
                    }
                    }/>
                    <ConfirmDialog visible={visibleUnzipMessage} onHide={() => setVisibleUnzipMessage(false)} message={unArchiveQueueQuestion}
                                   icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => {
                        dispatch(archiveAudio(deletedUserInfo?.audioId, jwtToken?.jwtToken, sortData));
                        if (deletedUserInfo.audioId === selectedRowData?.audioId) {
                            setSelectedRowData(null);
                        }
                    }
                    }/>
                </div>
            </div>
            <BranchDialog/>
        </div>
    );
};

export default AudioList;
