import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from 'redux/hooks'
import {Button} from "primereact/button";
import {setActiveClick, setClearFilters} from "redux/actions/actions";
import {getMonitoringExcel, updateUserFields} from "../../../redux/api/apiMonitoringList";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";
import {ToggleButton} from "primereact/togglebutton";
import {autoReloadToggle} from "../../../redux/actions/actionsMonitoringList";
import {MultiSelect} from "primereact/multiselect";
import {OverlayPanel} from "primereact/overlaypanel";
import {Tag} from "primereact/tag";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

const MonitoringListNav = (props: any) => {
    const dispatch = useAppDispatch()
    const exportLoading = useAppSelector(state => state.MonitoringListReducer.exportLoading);
    const autoReload = useAppSelector(state => state.MonitoringListReducer.autoReload);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as any;
    const monitoringPermissions = checkPermissions(rolesVision, 'Monitoring');
    const userSortFields: any = useAppSelector(state => state.RolesReducer?.userSortFields);
    const [selectedFields, setSelectedFields] = useState<any>(userSortFields?.fields);
    const op = useRef<OverlayPanel>(null);
    const {
        update: hasUpdatePermission,
    } = monitoringPermissions;

    let filteredObject = {
        "sort": props.sortData?.sort,
        "filter": props.sortData?.filter
    };

    useEffect(() => {
        setSelectedFields(userSortFields.fields);
    }, []);

    useEffect(() => {
        setSelectedFields(userSortFields.fields);
    }, [userSortFields]);

    useEffect(() => {
        if (autoReload) {
            dispatch(setActiveClick({item: '/monitoring', click: true}));
        }
    }, [autoReload]);

    const onColumnToggle = (e: { value: { name: any; }[]; }) => {
        const updatedFields = selectedFields.map((field: { name: any; inSystem: any; }) => {
            if (!field.inSystem) {
                return {
                    ...field,
                    isActive: e.value.some((selected: { name: any; }) => selected.name === field.name)
                };
            }
            return field;
        });
        setSelectedFields(updatedFields);
    };

    const saveUserFields = () => {
        dispatch(updateUserFields(selectedFields, jwtToken?.jwtToken));
    };

    const panelHeaderTemplate = () => {
        return (
            <div className="flex justify-content-between field col-12 mt-2">
                <Button icon="pi pi-times" className="p-button p-component p-button-secondary p-button-icon-only" onClick={() =>{
                    // @ts-ignore
                    op.current.hide();
                }}></Button>
                <Button icon="pi pi-check" onClick={(e) => {
                    saveUserFields();
                    // @ts-ignore
                    op.current.toggle(e);
                }}></Button>
            </div>
        );
    };

    const itemTemplate = (option: any) => {
        let itemName:string;
        switch (option.name) {
            case "nameTo":
                itemName = 'Клiент';
                break;
            case "ownerCall":
                itemName = 'Власник дзвінка';
                break;
            case "fullName":
                itemName = 'Оператор';
                break;
            case "direction":
                itemName = 'Тип Активності';
                break;
            case "campaignFullName":
                itemName = 'ID / Назва Кампанii';
                break;
            case "queueFullName":
                itemName = 'ID / Назва Черги';
                break;
            case "status":
                itemName = 'Статус';
                break;
            case "typeChannel":
                itemName = 'Активність';
                break;
            case "callResetName":
                itemName = 'Завершив';
                break;
            case "amdHangup":
                itemName = 'Автовідповідач';
                break;
            case "start":
                itemName = 'Початок';
                break;
            case "duration":
                itemName = 'Тривалість розмови';
                break;
            case "fullCallDuration":
                itemName = 'Тривалість дзвінка';
                break;
            case "reactionClient":
                itemName = 'Реакція Клієнта';
                break;
            case "reactionOperator":
                itemName = 'Реакція Оператора';
                break;
            case "end":
                itemName = 'Завершення';
                break;
            default:
                itemName = option.name;
        }

        return (
            <div className="custom-item">
                <span>{itemName}</span>
            </div>
        );
    }

    const selectedItemTemplate = (option: any) => {
        if (!option) {
            return <p className="opacity-50">Нічого не обрано</p>;
        }

        let itemName:string;

        switch (option.name) {
            case "nameTo":
                itemName = 'Клiент';
                break;
            case "ownerCall":
                itemName = 'Власник дзвінка';
                break;
            case "fullName":
                itemName = 'Оператор';
                break;
            case "direction":
                itemName = 'Тип Активності';
                break;
            case "campaignFullName":
                itemName = 'ID / Назва Кампанii';
                break;
            case "queueFullName":
                itemName = 'ID / Назва Черги';
                break;
            case "status":
                itemName = 'Статус';
                break;
            case "typeChannel":
                itemName = 'Активність';
                break;
            case "callResetName":
                itemName = 'Завершив';
                break;
            case "amdHangup":
                itemName = 'Автовідповідач';
                break;
            case "start":
                itemName = 'Початок';
                break;
            default:
                itemName = option.name;
        }

        return <Tag
            value={itemName} // Отображаем имя опции
            className="custom-chip"
        ></Tag>;
    };

    const onRowReorder = (e: { dragIndex: number; dropIndex: number; }) => {
        let updatedRows = [...selectedFields];
        const draggedRow = updatedRows[e.dragIndex];

        updatedRows.splice(e.dragIndex, 1);
        updatedRows.splice(e.dropIndex, 0, draggedRow);

        updatedRows = updatedRows.map((row, index) => ({
            ...row,
            order: index + 1
        }));

        setSelectedFields(updatedRows);
    }

    const handleDelete = (index: number) => {
        const updatedFields = selectedFields.map((field: any, i: number) =>
            i === index ? { ...field, isActive: false } : field
        );
        setSelectedFields(updatedFields);
    }

    const columnGroupItems = () => {
        const filteredData = selectedFields
            ?.filter(
                (field: { isActive: boolean; inSystem: boolean }) =>
                    field.isActive && !field.inSystem
            )
            ?.sort((a: { order: number }, b: { order: number }) => a.order - b.order);

        const normalizeDisplayOrder = (array: any[]) => {
            return array?.map((item, index) => ({
                ...item,
                order: index + 1,
            }));
        };

        const filteredArray = normalizeDisplayOrder(filteredData);

        const nameField = (rowData: any) => {
            let itemName:string;

            switch (rowData.name) {
                case "nameTo":
                    itemName = 'Клiент';
                    break;
                case "ownerCall":
                    itemName = 'Власник дзвінка';
                    break;
                case "fullName":
                    itemName = 'Оператор';
                    break;
                case "direction":
                    itemName = 'Тип Активності';
                    break;
                case "campaignFullName":
                    itemName = 'ID / Назва Кампанii';
                    break;
                case "queueFullName":
                    itemName = 'ID / Назва Черги';
                    break;
                case "status":
                    itemName = 'Статус';
                    break;
                case "typeChannel":
                    itemName = 'Активність';
                    break;
                case "callResetName":
                    itemName = 'Завершив';
                    break;
                case "amdHangup":
                    itemName = 'Автовідповідач';
                    break;
                case "start":
                    itemName = 'Початок';
                    break;
                case "duration":
                    itemName = 'Тривалість розмови';
                    break;
                case "fullCallDuration":
                    itemName = 'Тривалість дзвінка';
                    break;
                case "reactionClient":
                    itemName = 'Реакція Клієнта';
                    break;
                case "reactionOperator":
                    itemName = 'Реакція Оператора';
                    break;
                case "end":
                    itemName = 'Завершення';
                    break;
                default:
                    itemName = rowData.name;
            }

            return <p className="m-0">{itemName}</p>;
        }

        const buttonTemplate = (rowData: any, columnProps: any) => {
            const originalIndex = selectedFields.findIndex((field: any) => field.name === rowData.name); // Получаем оригинальный индекс в selectedFields
            return !rowData?.inSystem ? (
                <Button
                    onClick={() => handleDelete(originalIndex)} // Удаляем по индексу из оригинального массива
                    icon="pi pi-times"
                    className="delete-user p-button-secondary p-button-rounded p-button-text field col-12 md:col-2 mb-0 px-0"
                    disabled={!hasUpdatePermission}
                />
            ) : <></>;
        }

            return <DataTable className="w-full table-selected-fields" value={filteredArray} reorderableColumns reorderableRows
                              onRowReorder={onRowReorder}>
                <Column className="custom-column-width-20" rowReorder style={{width: '20px', maxWidth: '20px'}}/>
                <Column field="order" className="text-center" style={{minWidth: '10px', width: '40px', maxWidth: '40px'}}/>
                <Column field="name" body={nameField} style={{minWidth: '50%', maxWidth: '70%', justifyContent: 'flex-start'}}/>
                <Column body={buttonTemplate} style={{width: '20px', padding: 0, maxWidth: '20px'}}/>
            </DataTable>
        };

    return (
        <div className="table-buttons">
            <div className="text-normal-weight">
                <Button
                    label="Мої активності"
                    className={props.activeTab === 'table1' ? 'mr-2 mb-2' : 'mr-2 mb-2'}
                />
                <ToggleButton style={{fontWeight: 'normal'}} onLabel={'Автооновлення'} offLabel={'Ручне оновлення'} checked={autoReload} onChange={(e) => dispatch(autoReloadToggle(!autoReload))} className="w-10rem mb-2 mr-2 font-normal"/>

                <Button className="mb-2" type="button" icon="pi pi-cog"
                        onClick={(e) => {
                            // @ts-ignore
                            op.current.toggle(e);
                            setSelectedFields(userSortFields?.fields);

                }}/>
                <OverlayPanel ref={op}>
                    <div className="flex flex-column">
                        <div className="mb-3 text-bold">Виберіть стовпці для відображення:</div>
                        <MultiSelect
                            value={selectedFields?.filter((field: any) => !field.inSystem && field?.isActive)}
                            options={selectedFields?.filter((field: { inSystem: any }) => !field.inSystem)}
                            optionLabel="name"
                            onChange={onColumnToggle}
                            className="w-full mb-2 sm:max-w-20rem max-w-14rem"
                            display="chip"
                            filter
                            selectedItemTemplate={selectedItemTemplate}
                            itemTemplate={itemTemplate}
                        />
                        {
                            selectedFields?.length ?
                                <div className="users-list">
                                    {columnGroupItems()}
                                </div>
                                :
                                <span className="no-users-message">Груп не обрано</span>
                        }


                        <div className="flex justify-content-between field mt-2 mb-0">
                            <Button icon="pi pi-times" className="p-button p-component p-button-secondary p-button-icon-only" onClick={(e) =>{
                                if (op.current) {
                                    op.current.toggle(e);
                                }
                            }}></Button>
                            <Button icon="pi pi-check" onClick={() => {
                                saveUserFields();
                                if (op.current) {
                                    op.current.hide();
                                }
                            }}></Button>
                        </div>
                    </div>
                </OverlayPanel>
            </div>
            <div className="desktop">
                {hasUpdatePermission && <Button onClick={() => dispatch(getMonitoringExcel(filteredObject, jwtToken?.jwtToken))}
                                                label="Експорт таблиці"
                                                loading={exportLoading}
                                                icon="pi pi-file-excel"
                                                style={{backgroundColor: '#1d6f42'}}
                                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                />}
                <Button type="button" icon="pi pi-filter-slash" label="Очистити фільтри" className="p-button-outlined mr-2 mb-2" onClick={() => {
                    props.resetSorting()
                }}/>
            </div>
            <div className="mobile">
                {hasUpdatePermission && <Button onClick={() => dispatch(getMonitoringExcel(filteredObject, jwtToken?.jwtToken))}
                                                icon="pi pi-file-excel"
                                                loading={exportLoading}
                                                style={{backgroundColor: '#1d6f42'}}
                                                className="mr-2 mb-2 p-button-success text-white shadow-none"
                />}
                <Button type="button" icon="pi pi-filter-slash" className="p-button-outlined mr-2 mb-2" onClick={() => dispatch(setClearFilters())}/>
            </div>
        </div>
    )
}

export default MonitoringListNav;
